import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfo } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

import SearchInfo from './SearchInfo.js';
import styles from '../assets/scss/SearchForm.module.scss';
import { withDataSet } from '../context.js';

export default withDataSet((props) => {
    const [openInfo, setOpenInfo] = useState(false);
    const [queryValue, setQueryValue] = useState();

    useEffect(() => {
        setQueryValue(props.query);
    }, [props.query]);

    const toggleOpenInfo = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Toggle search help',
            value: openInfo ? 0 : 1,
        });
        setOpenInfo(!openInfo);
    };

    const onQueryChange = (evt) => {
        setQueryValue(evt.currentTarget.value);
        props.onChange(evt);
    };

    return (
        <form className={styles[`${openInfo ? 'search-form-open' : 'search-form-container'}`]} onSubmit={props.onSubmit}>
            <div className={styles['search-form']}>
                <div className={styles['search-input-group']}>
                    <FontAwesomeIcon icon={faSearch} className={styles['search-icon']} />
                    <span className={styles['input-sizer']} data-value={queryValue}>
                        <textarea className={styles['search-input']} onChange={onQueryChange} aria-label={props.placeholder} placeholder={props.placeholder} value={queryValue} />
                    </span>
                    <div className={styles['info-button']} onClick={toggleOpenInfo} aria-controls="search-info-text" aria-expanded={openInfo}>
                        <FontAwesomeIcon icon={faInfo} className={styles['search-icon']} />
                    </div>
                </div>
                <button type="submit" className={styles['search-button']}>
                    Search
                </button>
                {props.withFilters ? (
                    <span className={styles['filters-button']} onClick={() => props.setShowFilters(!props.showFilters)}>
                        filters
                    </span>
                ) : null}
            </div>
            <SearchInfo collapsed={openInfo} />
        </form>
    );
});
