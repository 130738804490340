import { FilterBasedAccessor, ObjectState, RangeQuery, FieldContext, FieldContextFactory } from 'searchkit';

class DateRangeAccessor extends FilterBasedAccessor {
    state = new ObjectState({});
    fieldContext = FieldContext;

    constructor(key, options) {
        super(key, options.id);
        this.options = options;
        this.options.fieldOptions = this.options.fieldOptions || { type: 'embedded' };
        this.options.fieldOptions.field = this.options.field;
        this.fieldContext = FieldContextFactory(this.options.fieldOptions);
    }

    getSelectedValue(value) {
        return `${value.startDate} - ${value.endDate}`;
    }

    resetState() {
        super.resetState();
        this.options.onReset();
    }

    buildSharedQuery(query) {
        if (this.state.hasValue()) {
            const state = this.state.getValue();
            const rangeFilter = this.fieldContext.wrapFilter(
                RangeQuery(this.options.field, {
                    gte: state.startDate,
                    lte: state.endDate,
                    format: this.options.dateFormat,
                }),
            );
            const selectedFilter = {
                name: this.options.title,
                value: `${state.startDate} - ${state.endDate}`,
                id: this.options.id,
                remove: () => this.resetState(),
            };

            return query.addFilter(this.uuid, rangeFilter).addSelectedFilter(selectedFilter);
        }
        return query;
    }
}

export default DateRangeAccessor;
