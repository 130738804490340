import React from 'react';
import { FacetFilter, RefinementListFilter, NumericRefinementListFilter } from 'searchkit';

import BarChart from './BarChart.js';
import Choropleth from './Choropleth.js';
import TreeMap from './TreeMap.js';

const BarChartFilter = ({ width, height, margin, svgRef, ...props }) => (
    <FacetFilter listComponent={(componentProps) => <BarChart width={width} height={height} margin={margin} multiselect={props.multiselect} svgRef={svgRef} {...componentProps} />} {...props} />
);

const BarChartNumericFilter = ({ width, height, margin, svgRef, ...props }) => (
    <NumericRefinementListFilter
        listComponent={(componentProps) => <BarChart width={width} height={height} margin={margin} multiselect={props.multiselect} svgRef={svgRef} {...componentProps} />}
        {...props}
    />
);

const TreeMapFilter = ({ width, height, margin, svgRef, ...props }) => (
    <RefinementListFilter
        listComponent={(componentProps) => <TreeMap width={width} height={height} margin={margin} multiselect={props.multiselect} svgRef={svgRef} {...componentProps} />}
        {...props}
    />
);

const ChoroplethFilter = ({ width, height, margin, svgRef, ...props }) => (
    <RefinementListFilter
        listComponent={(componentProps) => <Choropleth width={width} height={height} margin={margin} multiselect={props.multiselect} svgRef={svgRef} {...componentProps} />}
        {...props}
    />
);

export { BarChartFilter, BarChartNumericFilter, TreeMapFilter, ChoroplethFilter };
