import React from 'react';
import { Group } from '@vx/group';
import { scaleQuantize } from '@vx/scale';
import { Mercator } from '@vx/geo';
import * as topojson from 'topojson';
import topology from '../../config/world.topo.json';
import range from 'lodash/range';
import find from 'lodash/find';

import styles from '../../assets/scss/Charts.module.scss';

export default ({ width, height, items, selectedItems, setItems, multiselect, toggleItem, svgRef }) => {
    const data = items.map((item) => ({ key: item.key, value: item.doc_count }));

    if (!data.length) {
        return null;
    }

    const world = topojson.feature(topology, topology.objects.world);
    const minValue = Math.min(...data.map((d) => d.value));
    const maxValue = Math.max(...data.map((d) => d.value));

    const color = scaleQuantize({
        domain: range(minValue, maxValue, (maxValue - minValue) / 6).map(Math.floor),
        range: range(1, 7).map((i) => styles[`geoBackground${i}`]),
    });

    const toggleFunc = multiselect ? toggleItem : (key) => setItems([key]);

    const centerX = width / 2;
    const centerY = height / 2;
    const scale = (width / 630) * 100;

    return (
        <svg width={width} height={height} ref={svgRef} xmlns="http://www.w3.org/2000/svg">
            <Group className="chart-group">
                <Mercator data={world.features} scale={scale} translate={[centerX, centerY + 50]}>
                    {(mercator) => {
                        return (
                            <g>
                                {mercator.features.map((feature, i) => {
                                    const { feature: f } = feature;
                                    const datum = find(data, (d) => d.key === f.properties.name);
                                    let featureColour = styles['geoBackground'];
                                    if (datum) {
                                        if (selectedItems.includes(datum.key)) {
                                            featureColour = styles['selectedGeoBackground'];
                                        } else {
                                            featureColour = color(datum.value);
                                        }
                                    }
                                    return (
                                        <path
                                            key={`map-feature-${i}`}
                                            d={mercator.path(f)}
                                            fill={featureColour}
                                            stroke={styles['geoBorderColor']}
                                            strokeWidth={0.5}
                                            onClick={() => {
                                                if (datum) {
                                                    toggleFunc(datum.key);
                                                }
                                            }}
                                        >
                                            <title>{f.properties.name}</title>
                                        </path>
                                    );
                                })}
                            </g>
                        );
                    }}
                </Mercator>
            </Group>
        </svg>
    );
};
