import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchkitProvider, NoHits, Hits, Pagination, InitialLoader, SortingSelector } from 'searchkit';
import 'searchkit/theming/theme.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faChartPie } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

import SearchBox from './SearchBox.js';
import DataSetList from './DataSetList.js';
import HitsList from './Hits.js';
import CustomHitStats from './CustomHitStats.js';
import PaginationStats from './PaginationStats.js';
import FacetList from './FacetList/index.js';
import Charts from './Charts';
import RegistriesTable from './RegistriesTable';
import { LogoBanner } from './Footers';
import { SynonymSearchKitManager } from '../searchkit/SynonymSearchKitManager';

import styles from '../assets/scss/ResultsPage.module.scss';
import { withDataSet } from '../context.js';

export default withDataSet(({ dataSet }) => {
    const history = useHistory();
    const location = useLocation();
    const [expandQuery, setExpandQuery] = useState(true);

    React.useEffect(() => {
        const storedParams = JSON.parse(sessionStorage.getItem('query-params') || '{}');
        storedParams[dataSet.index] = location.search;
        sessionStorage.setItem('query-params', JSON.stringify(storedParams));
    }, [location, dataSet]);

    const searchkit = new SynonymSearchKitManager(`/api/query/${dataSet.index}`, { createHistory: () => history, expandQuery, setExpandQuery });
    const [showDetailedView, setShowDetailedView] = useState(sessionStorage.getItem('showDetailedView') === 'true');
    const [showFilters, setShowFilters] = useState(true);
    const [tabKey, setTabKey] = useState('text-results');
    const filterClass = showFilters ? styles['filters'] : styles[('filters', 'filters--hidden')];

    return (
        <SearchkitProvider searchkit={searchkit}>
            <div className="results">
                <div className={styles['search-area']}>
                    <DataSetList path="search" storedLocation />
                    <div className={styles['searchbox-container']}>
                        <SearchBox queryFields={dataSet.queryFields} placeholder={dataSet.content.searchPlaceholder} withFilters={true} showFilters={showFilters} setShowFilters={setShowFilters} />
                    </div>
                    <div className={styles['toggle-details']}>
                        <section>Detailed View</section>
                        <label className={styles['switch']}>
                            <input
                                type="checkbox"
                                checked={showDetailedView}
                                onChange={() => {
                                    sessionStorage.setItem('showDetailedView', !showDetailedView);
                                    setShowDetailedView(!showDetailedView);
                                    ReactGA.event({
                                        category: 'Navigation',
                                        action: 'Toggle detail view',
                                        value: showDetailedView ? 0 : 1,
                                    });
                                }}
                            />
                            <span className={styles['slider-round']}></span>
                        </label>
                    </div>
                </div>
                <div className={styles['results-area']}>
                    <div className={filterClass}>
                        <FacetList />
                    </div>

                    <div className={styles['content']}>
                        <div className={styles['titleContainer']}>
                            <h3 className={styles['results-page-title']}>Search results</h3>
                            <div>
                                <CustomHitStats expandQuery={expandQuery} />
                            </div>
                        </div>
                        <Tab.Container
                            activeKey={tabKey}
                            onSelect={(k) => {
                                setTabKey(k);
                                ReactGA.event({
                                    category: 'Navigation',
                                    action: k,
                                });
                            }}
                        >
                            <div className={styles['tabs-sorting-container']}>
                                <Nav variant="pills" className={styles['result-tabs']}>
                                    <Nav.Link eventKey="text-results" className={styles['result-tab-left']}>
                                        <FontAwesomeIcon icon={faList} />
                                        Text results
                                    </Nav.Link>
                                    <Nav.Link eventKey="visualisations" className={styles['result-tab-right']}>
                                        <FontAwesomeIcon icon={faChartPie} />
                                        Visualisation data &amp; trends
                                    </Nav.Link>
                                </Nav>
                                {/* TODO: Not a scooby why the SortingSelector disappears in a ternary, this seems to work for now */}
                                <div className={tabKey === 'visualisations' ? 'd-none' : ''}>
                                    <SortingSelector className={styles['sorting-selector']} options={dataSet.sortOptions} />
                                </div>
                            </div>

                            <InitialLoader
                                component={() => (
                                    <div className={styles['loader']} title="Loading please wait">
                                        loading please wait...
                                    </div>
                                )}
                            />

                            <Tab.Content>
                                <Tab.Pane eventKey="text-results" className={styles['text-results']}>
                                    <NoHits suggestionsField={dataSet.hitItem.titleField} />

                                    <Hits
                                        sourceFilter={dataSet.sourceFilter}
                                        highlightFields={dataSet.highlightFields}
                                        customHighlight={{
                                            pre_tags: [`<em class="${styles['highlight']}">`],
                                            post_tags: ['</em>'],
                                        }}
                                        listComponent={<HitsList showDetailedView={showDetailedView} />}
                                        hitsPerPage={25}
                                    />
                                    <div className={styles['pagination-container']}>
                                        <Pagination
                                            showNumbers={true}
                                            translations={{
                                                'pagination.previous': '< Prev',
                                                'pagination.next': 'Next >',
                                            }}
                                            mod="sk-pagination"
                                            pageScope={2}
                                        />
                                    </div>
                                    <PaginationStats mod="sk-pagination-stats" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="visualisations">
                                    <NoHits suggestionsField={dataSet.hitItem.titleField} />
                                    <Charts />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        <RegistriesTable />
                    </div>
                </div>
                <LogoBanner />
            </div>
        </SearchkitProvider>
    );
});
