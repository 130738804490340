import React from 'react';
import { Group } from '@vx/group';
import { Treemap } from '@vx/hierarchy';
import { hierarchy, stratify } from 'd3-hierarchy';
import { treemapSquarify } from 'd3-hierarchy';
import { Text } from '@vx/text';
import range from 'lodash/range';

import styles from '../../assets/scss/Charts.module.scss';

export default ({ width, height, items, selectedItems, setItems, multiselect, toggleItem, svgRef }) => {
    const data = [{ key: 'root', parent: null }].concat(items.map((item) => ({ key: item.key, value: item.doc_count, parent: 'root' })));

    if (!data.length) {
        return null;
    }

    const colorRange = range(1, 8).map((i) => styles[`treeMapBackground${i}`]);
    const stratified = stratify()
        .id((d) => d.key)
        .parentId((d) => d.parent)(data)
        .sum((d) => d.value || 0);

    const root = hierarchy(stratified).sort((a, b) => b.value - a.value);
    const toggleFunc = multiselect ? toggleItem : (key) => setItems([key]);

    return (
        <svg width={width} height={height} ref={svgRef} xmlns="http://www.w3.org/2000/svg">
            <Group className="chart-group">
                <Treemap root={root} size={[width, height]} tile={treemapSquarify} round={true}>
                    {(treemap) => {
                        const nodes = treemap.descendants().reverse();
                        return (
                            <Group>
                                <Group>
                                    {nodes.map((node, i) => {
                                        const { x1, x0, y1, y0 } = node;
                                        const width = isNaN(x1) || isNaN(x0) ? 0 : x1 - x0;
                                        const height = isNaN(y1) || isNaN(y0) ? 0 : y1 - y0;
                                        const { key, value } = node.data.data;

                                        return (
                                            node.depth === 1 && (
                                                <Group key={`treemap-node-${i}`} top={isNaN(y0) ? 0 : y0} left={isNaN(x0) ? 0 : x0}>
                                                    <rect width={width} height={height} stroke={styles['treeMapStroke']} strokeWidth={1} fill={colorRange[i % colorRange.length]}>
                                                        <title>{`${key} (${value})`}</title>
                                                    </rect>
                                                    <rect
                                                        x={2}
                                                        y={2}
                                                        width={width - 4}
                                                        height={height - 4}
                                                        fill="transparent"
                                                        strokeWidth={4}
                                                        stroke={selectedItems.includes(key) ? styles['treeMapStrokeSelected'] : 'transparent'}
                                                        onClick={() => toggleFunc(key)}
                                                    />
                                                    <svg width={width} height={height} onClick={() => toggleFunc(key)}>
                                                        <Text dx={4} dy={12} className={styles['treeMapCount']} fill={styles['treeMapStroke']}>
                                                            {value}
                                                        </Text>
                                                        <Text dx={4} dy={16} className={styles['treeMapKey']} fill={styles['treeMapStroke']} verticalAnchor="start" width={width}>
                                                            {key}
                                                        </Text>
                                                    </svg>
                                                </Group>
                                            )
                                        );
                                    })}
                                </Group>
                            </Group>
                        );
                    }}
                </Treemap>
            </Group>
        </svg>
    );
};
