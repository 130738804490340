import React, { useState, useEffect, createRef } from 'react';
import isUndefined from 'lodash/isUndefined';

import * as Filters from './filters.js';
import styles from '../../assets/scss/Charts.module.scss';
import { withDataSet } from '../../context.js';
import * as watermarkSrc from '../../assets/img/watermark.png';
import ReactGA from 'react-ga';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faCompress, faDownload } from '@fortawesome/free-solid-svg-icons';

export default withDataSet(({ dataSet }) => {
    const [expand, setExpand] = useState(null);
    const [responsiveWidth, setResponsiveWidth] = useState();
    const [responsiveHeight, setResponsiveHeight] = useState();
    const [expandedWidth, setExpandedWidth] = useState();
    const [expandedHeight, setExpandedHeight] = useState();
    const [screenWidth, setScreenWidth] = useState();

    function handleResize() {
        if (screenWidth === window.innerWidth) {
            return;
        }

        const _screenWidth = window.innerWidth;

        //"Put these numbers somewhere else at some point in the future"
        setResponsiveWidth(_screenWidth * 0.71);
        setResponsiveHeight(_screenWidth * 0.43);
        setExpandedWidth(_screenWidth * 0.71);
        setExpandedHeight(_screenWidth * 0.43);

        if (_screenWidth >= 768) {
            setResponsiveWidth(_screenWidth * 0.27);
            setResponsiveHeight(_screenWidth * 0.18);
            setExpandedWidth(_screenWidth * 0.61);
            setExpandedHeight(responsiveHeight * 2 + 90);
        }
        if (_screenWidth >= 1000) {
            setResponsiveWidth(_screenWidth * 0.21);
            setResponsiveHeight(_screenWidth * 0.14);
            setExpandedWidth(_screenWidth * 0.48);
            setExpandedHeight(_screenWidth * 0.28);
        }
        if (_screenWidth >= 1400) {
            setResponsiveWidth(_screenWidth * 0.14);
            setResponsiveHeight(_screenWidth * 0.1);
            setExpandedWidth(_screenWidth * 0.5);
            setExpandedHeight(_screenWidth * 0.29);
        }
        if (_screenWidth >= 1600) {
            setResponsiveWidth(_screenWidth * 0.15);
            setExpandedWidth(_screenWidth * 0.505);
        }

        setScreenWidth(_screenWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return (_) => {
            window.removeEventListener('resize', handleResize);
        };
    });

    handleResize();

    const toDataURL = async (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                canvas.getContext('2d').drawImage(this, 0, 0);
                try {
                    resolve(canvas.toDataURL());
                } catch (e) {
                    reject();
                }
            };
            img.src = url;
        });
    };

    const canDownloadImg = (() => {
        return !isUndefined(SVGElement.prototype.getElementsByClassName);
    })();

    const downloadImg = async (svgRef, title) => {
        const svg = svgRef.current.cloneNode(true);
        svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');

        const transformScale = 4;
        const padding = 20;
        const watermarkWidth = 300;
        const watermarkHeight = 85;
        const titleHeight = 20;

        svg.setAttribute('width', svg.attributes.width.nodeValue * transformScale + padding);
        svg.setAttribute('height', svg.attributes.height.nodeValue * transformScale + titleHeight + watermarkHeight + padding);

        const styleElement = document.createElementNS(svg.namespaceURI, 'style');
        styleElement.appendChild(
            document.createTextNode(`
svg {
    background-color: #fff;
    font-family: sans, sans-serif;
}
text {
    transform: translate(0, calc(-1.5rem / ${transformScale}));
}
text tspan {
    font-size: calc(16px / ${transformScale});
}
text.title {
    font-size: ${titleHeight}px;
    font-weight: bold;
}
`),
        );
        svg.appendChild(styleElement);

        const chartGroup = svg.getElementsByClassName('chart-group').item(0);
        chartGroup.setAttribute('transform', `translate(${padding / 2}, ${titleHeight + padding}) scale(${transformScale})`);

        const titleElement = document.createElementNS(svg.namespaceURI, 'text');
        titleElement.setAttribute('x', padding / 2);
        titleElement.setAttribute('y', titleHeight + padding / 2);
        titleElement.setAttribute('class', 'title');
        titleElement.appendChild(document.createTextNode(title));
        svg.appendChild(titleElement);

        const imageDataUrl = await toDataURL(watermarkSrc);

        const watermarkElement = document.createElementNS(svg.namespaceURI, 'image');
        watermarkElement.setAttributeNS(null, 'x', 0);
        watermarkElement.setAttributeNS(null, 'y', svg.attributes.height.nodeValue - watermarkHeight);
        watermarkElement.setAttributeNS(null, 'width', watermarkWidth);
        watermarkElement.setAttributeNS(null, 'height', watermarkHeight);
        watermarkElement.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', imageDataUrl);
        svg.appendChild(watermarkElement);

        const blob = new Blob([svg.outerHTML], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);
        const canvas = document.createElement('canvas');
        const canvasCtx = canvas.getContext('2d');
        const img = document.createElement('img');

        img.addEventListener(
            'load',
            () => {
                canvas.width = svg.attributes.width.nodeValue;
                canvas.height = svg.attributes.height.nodeValue;
                canvasCtx.drawImage(img, 0, 0);

                if (canvas.msToBlob) {
                    const msBlob = canvas.msToBlob();
                    window.navigator.msSaveBlob(msBlob, 'chart.png');
                } else {
                    const link = document.createElement('a');
                    link.download = 'chart.png';
                    link.href = canvas.toDataURL('image/png');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }

                URL.revokeObjectURL(url);
            },
            { once: true },
        );

        img.src = url;

        ReactGA.event({
            category: 'Download',
            action: 'chart',
        });
    };

    return (
        <div className={styles['chart-container']}>
            {dataSet.visualisations.map(({ props, type }, idx) => {
                const svgRef = createRef();

                if (expand === props.id) {
                    return (
                        <div key={idx} className={`${styles['chart']} ${styles['chart-expanded']}`}>
                            <button onClick={() => setExpand(null)} className={styles['icon-expanded']}>
                                <FontAwesomeIcon icon={faCompress} />
                            </button>
                            {React.createElement(Filters[type], {
                                ...props,
                                width: expandedWidth,
                                height: expandedHeight,
                                key: idx,
                                svgRef: svgRef,
                            })}
                        </div>
                    );
                }

                return (
                    <div key={idx} className={styles['chart']}>
                        {canDownloadImg ? (
                            <button onClick={() => downloadImg(svgRef, props.title)} className={styles['icon-download']}>
                                <FontAwesomeIcon icon={faDownload} />
                            </button>
                        ) : (
                            <button disabled className={styles['icon-download']} title="Image download is unavailable in your browser">
                                <FontAwesomeIcon icon={faDownload} className="fa-disabled" />
                            </button>
                        )}
                        <button onClick={() => setExpand(props.id)} className={styles['icon-collapsed']}>
                            <FontAwesomeIcon icon={faExpand} />
                        </button>
                        {React.createElement(Filters[type], {
                            ...props,
                            width: screenWidth >= 1400 ? responsiveWidth * props.widthRatio : responsiveWidth,
                            height: responsiveHeight,
                            key: idx,
                            svgRef: svgRef,
                        })}
                    </div>
                );
            })}
        </div>
    );
});
