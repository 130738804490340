import React, { useState, createRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SearchkitManager, SearchkitProvider, TermQuery, Hits, NoHits } from 'searchkit';
import kebabCase from 'lodash/kebabCase';
import ReactGA from 'react-ga';

import SearchForm from './SearchForm';
import DataSetList from './DataSetList.js';
import { LogoBanner } from './Footers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faDownload } from '@fortawesome/free-solid-svg-icons';

import styles from '../assets/scss/DetailPage.module.scss';
import { withDataSet } from '../context.js';
import * as Widgets from './Widgets.js';
import { FlagData, ConfirmationModal } from './FormModals.js';
import { Error404Content } from './HygienePages.js';

const ResultDetail = withDataSet(({ hits, dataSet }) => {
    const [showFlagData, setShowFlagData] = React.useState(false);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const source = hits[0]._source;

    function mapWidgetFields(fields) {
        return fields.map((field, key) => React.createElement(Widgets[field.widgetType], { ...field.widgetProps, key }, source[field.fieldName]));
    }

    const contentRefs = dataSet.detailItem.contentFields.reduce(
        (acc, field) => {
            if (source[field.fieldName]) {
                acc[kebabCase(field.widgetProps.title)] = createRef();
            }
            return acc;
        },
        { 'key-facts': createRef() },
    );

    const scrollToRef = (key) => {
        window.scrollTo(0, contentRefs[key].current.offsetTop);
        window.history.replaceState({}, key, `${window.location.pathname}#${key}`);
    };

    const downloadSource = () => {
        const delimiter = ',';
        const headers = dataSet.downloadFields;
        const csvString = [
            headers.map((header) => (header == 'PubList' ? 'Publication ID' : header)).join(delimiter),
            headers
                .map((header) => {
                    let item = source[header];
                    if (header === 'PubList' && item) {
                        item = item.toString().split(',').join(', ');
                    }
                    return item ? `"${item.toString().replace(/"/g, "'")}"` : '';
                })
                .join(delimiter),
        ].join('\r\n');

        const blob = new Blob([csvString]);
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'download.csv');
        } else {
            const a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(blob, {
                type: 'text/csv',
            });
            a.download = 'download.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        ReactGA.event({
            category: 'Download',
            action: 'detail',
        });
    };

    return (
        <div className={styles['detail-page']}>
            <div className={styles['toc']}>
                <div className={styles['toc-content']}>
                    <ul>
                        <li>
                            <h4>CONTENTS</h4>
                        </li>
                        <li>
                            <button className="btn-link" onClick={() => scrollToRef('key-facts')}>
                                Key facts
                            </button>
                        </li>
                        {dataSet.detailItem.contentFields.map(
                            (field, key) =>
                                source[field.fieldName] && (
                                    <li key={key}>
                                        <button className="btn-link" onClick={() => scrollToRef(kebabCase(field.widgetProps.title))}>
                                            {field.widgetProps.title}
                                        </button>
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
            </div>
            <div className={styles['content']}>
                <h2>{source[dataSet.detailItem.titleField]}</h2>
                <hr />
                <dl className={styles['meta-detail']}>{mapWidgetFields(dataSet.detailItem.metaDetailFields)}</dl>
                <div className={styles['content-buttons']}>
                    <button onClick={() => setShowFlagData(true)} className={styles['flag-button']}>
                        <span title="Report Data Issue">
                            <FontAwesomeIcon icon={faFlag} />
                        </span>
                    </button>
                    <button onClick={downloadSource} className={styles['download-button']}>
                        <span title="Download as CSV">
                            <FontAwesomeIcon icon={faDownload} />
                        </span>
                    </button>
                    <FlagData
                        show={showFlagData}
                        onHide={() => setShowFlagData(false)}
                        setShowFlagData={setShowFlagData}
                        setShowConfirmation={setShowConfirmation}
                        result={hits[0]}
                        title={'Report an issue'}
                    />
                    <ConfirmationModal show={showConfirmation} onHide={() => setShowConfirmation(false)} setShowConfirmation={setShowConfirmation} title={'Report an issue'} />
                </div>
                <div className={styles['detail-container']} ref={contentRefs['key-facts']}>
                    <dl className={styles['key-facts']} id="key-facts">
                        {mapWidgetFields(dataSet.detailItem.keyFactFields)}
                    </dl>
                    <hr />
                    <dl className={styles['external-links']}>{mapWidgetFields(dataSet.detailItem.externalLinkFields)}</dl>
                </div>
                {dataSet.detailItem.contentFields.map((field, key) => {
                    const fieldContent = source[field.fieldName];
                    if (fieldContent || fieldContent.length) {
                        return (
                            <div ref={contentRefs[kebabCase(field.widgetProps.title)]} key={key} className={styles['content-field']}>
                                {React.createElement(Widgets[field.widgetType], { ...field.widgetProps }, fieldContent)}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
});

export default withDataSet(({ dataSet }) => {
    const params = useParams();
    const history = useHistory();
    const [query, setQuery] = useState('');

    const searchkit = new SearchkitManager(`/api/query/${dataSet.index}`, { createHistory: () => history });

    searchkit.addDefaultQuery((query) => {
        return query.addQuery(TermQuery('_id', decodeURIComponent(params.id)));
    });

    function onSubmit(e) {
        e.preventDefault();
        history.push(`/${dataSet.id}/search?q=${query}`);
    }

    return (
        <SearchkitProvider searchkit={searchkit}>
            <div className="detail">
                <div className={styles['back-bar']}>
                    <button className={styles['back-button']} variant="link" onClick={() => window.history.back()} dangerouslySetInnerHTML={{ __html: '< Back to search results' }} />
                    <DataSetList path="search" />
                    <div className={styles['searchbox-container']}>
                        <SearchForm onChange={(e) => setQuery(e.target.value)} onSubmit={onSubmit} placeholder={dataSet.content.searchPlaceholder} />
                    </div>
                </div>
                <Hits listComponent={ResultDetail} />
                <NoHits component={<Error404Content className={styles['no-hits']} />} />
                <LogoBanner />
            </div>
        </SearchkitProvider>
    );
});
