import React from 'react';
import { HitsStats, Toggle } from 'searchkit';
import CustomPageSizeSelector from './CustomPageSizeSelector';
import { withDataSet } from '../context.js';
import styles from '../assets/scss/ResultsPage.module.scss';

class PaginationStats extends HitsStats {
    render() {
        const hitCount = this.searchkit.getHitsCount();
        if (!hitCount) {
            return null;
        }

        const numberOfHits = `${hitCount} file${hitCount === 1 ? '' : 's'}`;

        const resultsPerPage = this.searchkit.state.size || 25;
        const page = this.searchkit.state.p || 1;
        const displayedResults = `${resultsPerPage * page - (resultsPerPage - 1)}-${resultsPerPage * page}`;

        return (
            <div className={`${this.bemBlocks.container()} ${styles['pagination-stats-container']}`} data-qa="hits-stats">
                <section>
                    {displayedResults} of {numberOfHits}, showing{' '}
                </section>
                <CustomPageSizeSelector options={[25, 50, 100, 200]} listComponent={Toggle} />
                <section> per page</section>
            </div>
        );
    }
}

export default withDataSet(PaginationStats);
