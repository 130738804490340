import React from 'react';
import { Collapse } from 'react-bootstrap';

import styles from '../assets/scss/SearchForm.module.scss';

export default (props) => (
    <Collapse in={props.collapsed}>
        <div className={styles['search-info-text']}>
            <ul id="search-info-text">
                <li>
                    <strong>OR</strong> is assumed between multiple words if no other operator or phrase indication is used
                    <br />
                    e.g. <mark>heart attack</mark> searches for <mark>heart OR attack</mark>
                </li>
                <li>
                    For <strong>OR</strong> use <strong className={styles['symbol']}>|</strong> (with or without spaces)
                    <br />
                    e.g. <mark>heart|attack</mark>, <mark>heart | attack</mark> searches for <mark>heart OR attack</mark>
                </li>
                <li>
                    For <strong>AND</strong> use <strong className={styles['symbol']}>+</strong> (with or without spaces)
                    <br />
                    e.g. <mark>heart+attack</mark>, <mark>heart + attack</mark> searches for <mark>heart AND attack</mark>
                </li>
                <li>
                    For <strong>NOT</strong> use <strong className={styles['symbol']}>+ -</strong> [Hyphens are ignored when used without spaces between words; when used in front of a word for NOT,
                    it's essential to use the '+' as well]
                    <br />
                    e.g. <mark>heart + -attack</mark> searches for <mark>heart NOT attack</mark>
                </li>
                <li>
                    To search a <strong>multiple-word phrase</strong> use <strong>double quotation marks</strong> [Truncation cannot be used within phrases]
                    <br />
                    e.g. <mark>"heart attack"</mark> searches for the words <mark>"heart attack"</mark> together in that order
                </li>
                <li>
                    For <strong>truncation</strong> use <strong className={styles['symbol']}>*</strong> [Only for single words; truncation cannot be used within double quotation mark phrases]
                    <br />
                    e.g. <mark>cancer*</mark> Searches for <mark>cancer</mark>, <mark>cancers</mark>, <mark>cancerous</mark>, etc
                </li>
                <li>
                    For <strong>multiple operators</strong> within the same search line, use <strong>brackets</strong> [Brackets determine the order that operators are applied]
                    <br />
                    e.g. <mark>("heart attack" | "myocardial infarction") + (aspirin | thrombolytic* | fibrinolytic*)</mark> searches for{' '}
                    <mark>("heart attack" OR "myocardial infarction") AND (aspirin OR thrombolytic* OR fibrinolytic*)</mark>
                </li>
            </ul>
        </div>
    </Collapse>
);
