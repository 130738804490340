import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import SearchForm from './SearchForm.js';
import DataSetList from './DataSetList.js';
import { Footer, LogoBanner } from './Footers';
import logo from '../assets/img/home-logo-scanmedicine-white.png';

import styles from '../assets/scss/HomePage.module.scss';
import { withDataSet } from '../context.js';
import BetaLabel from './BetaLabel.js';

export default withDataSet((props) => {
    const history = useHistory();
    const [query, setQuery] = useState('');

    function onSubmit(e) {
        e.preventDefault();
        history.push(`/${props.dataSet.id}/search?q=${query}`);
    }

    return (
        <div id="home">
            <div className={styles['header']}></div>

            <div className={styles['home-container']}>
                <img className={styles['home-logo']} src={logo} alt="Scan Medicine" />
                <h1>Open access to medical datasources</h1>
                <div className={styles['search-container']}>
                    <DataSetList homepage={true} />
                    <SearchForm onChange={(e) => setQuery(e.target.value)} onSubmit={onSubmit} placeholder={props.dataSet.content.searchPlaceholder} />
                    <div className={styles['home-beta']}>
                        <BetaLabel />
                    </div>
                </div>
            </div>

            <div className={styles['footer-wrapper']}>
                <LogoBanner />
                <Footer />
            </div>
        </div>
    );
});
