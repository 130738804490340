import React from 'react';
import { SearchBox as BaseSearchBox } from 'searchkit';

import SearchForm from './SearchForm.js';

class SearchBox extends BaseSearchBox {
    searchQuery(query) {
        const shouldResetOtherState = true;
        this.accessor.setQueryString(query, shouldResetOtherState);
        const now = +new Date();
        const newSearch = now - this.lastSearchMs <= 2000;
        this.lastSearchMs = now;
        this.searchkit.performSearch(newSearch);
        sessionStorage.removeItem('query-params');
    }

    render() {
        if (!this.accessor) return null;
        return (
            <SearchForm
                onSubmit={this.onSubmit.bind(this)}
                onChange={this.onChange.bind(this)}
                query={this.getValue()}
                placeholder={this.props.placeholder}
                withFilters={this.props.withFilters}
                showFilters={this.props.showFilters}
                setShowFilters={this.props.setShowFilters}
            />
        );
    }
}

export default SearchBox;
