import React, { useState } from 'react';

import styles from '../assets/scss/RegistriesTable.module.scss';
import { withDataSet } from '../context.js';

const truncate = require('lodash/truncate');

const RegistriesTable = (props) => {
    const [viewMore, setViewMore] = useState(false);
    const pageSize = 20;
    const registryList = props.dataSet.content.listOfRegistries;
    const registryType = props.dataSet.content.registryType;

    if (registryList.length === 0) {
        return null;
    }

    const registryLozenge = (registry, key) => (
        <div key={key} className={styles['registry']}>
            <section>{registry.name}</section>
            {registry.link && (
                <a href={registry.link} target="_blank" rel="noopener noreferrer">
                    {truncate(registry.link, 120)}
                </a>
            )}
        </div>
    );

    return (
        <div className={styles['registries-container']}>
            <h5> List of registries </h5>
            <p>
                {' '}
                ScanMedicine searched the following {registryList.length} {registryType}{' '}
            </p>
            <div className={styles['registries-list']}>
                {registryList.slice(0, pageSize).map(registryLozenge)}
                {viewMore && registryList.slice(pageSize).map(registryLozenge)}
            </div>
            {registryList.length > pageSize && (
                <div className={styles['button-container']}>
                    <button className={styles['view-more-button']} onClick={() => setViewMore(!viewMore)}>
                        {viewMore ? 'View Less' : 'View More'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default withDataSet(RegistriesTable);
