import React from 'react';
import { HitsStats } from 'searchkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import styles from '../assets/scss/ResultsPage.module.scss';
import { withDataSet } from '../context.js';

class CustomHitStats extends HitsStats {
    constructor(props) {
        super(props);
        this.state = { showSynonyms: false };
    }

    render() {
        const results = this.getResults();
        if (!results) {
            return null;
        }

        const hitCount = results.hits.total;
        const query = this.searchkit.state.q;
        const synonyms = this.searchkit.synonyms.filter((s) => s.toLowerCase() !== (query || '').toLowerCase());
        const dataSet = this.props.dataSet;
        const registryFilter = this.searchkit.state.Registry;
        const registryFilterCount = registryFilter && registryFilter.length;

        const numberOfHits = `${hitCount} result${hitCount === 1 ? '' : 's'}`;
        let numberOfRegistries = '';
        if (dataSet.content.listOfRegistries && dataSet.content.listOfRegistries.length) {
            let registryCount = null;
            if (registryFilterCount) {
                registryCount = registryFilterCount;
            } else {
                registryCount = dataSet.content.listOfRegistries.length;
            }
            if (registryCount) {
                numberOfRegistries = `found in ${registryCount} registr${registryCount === 1 ? 'y' : 'ies'}`;
            }
        }

        const queryText = query && `for ${query}`;

        return (
            <div className={styles['hit-stats']}>
                {numberOfHits} {numberOfRegistries} {queryText}{' '}
                {hitCount && this.props.expandQuery && synonyms && synonyms.length ? (
                    <>
                        <button
                            className="btn-link"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({ showSynonyms: !this.state.showSynonyms });
                            }}
                        >
                            {` and ${synonyms.length} synonym${synonyms.length === 1 ? '' : 's'} `}
                            <FontAwesomeIcon icon={this.state.showSynonyms ? faAngleUp : faAngleDown} />
                        </button>
                        {this.state.showSynonyms && (
                            <p>
                                <em>also matching</em> <strong>{synonyms.join(', ')}</strong>
                            </p>
                        )}
                    </>
                ) : null}
            </div>
        );
    }
}

export default withDataSet(CustomHitStats);
