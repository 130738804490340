import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { QueryAccessor } from 'searchkit';

import { withDataSet } from '../context';
import { dataSets } from '../config/dataSets.js';
import styles from '../assets/scss/DataSetList.module.scss';
import { SynonymSearchKitManager } from '../searchkit/SynonymSearchKitManager';

const DataSetItem = ({ dataSet, selected, path, storedLocation }) => {
    const history = useHistory();
    const location = useLocation();
    const [query, setQuery] = useState();
    const [count, setCount] = useState();

    useEffect(() => {
        setQuery(new URLSearchParams(location.search).get('q'));
    }, [location]);

    useEffect(() => {
        if (query) {
            (async function doSearch() {
                const searchkit = new SynonymSearchKitManager(`/api/query/${dataSet.index}`, {
                    createHistory: () => history,
                    expandQuery: true,
                });
                searchkit.addAccessor(
                    new QueryAccessor('q', {
                        queryFields: dataSet.queryFields,
                    }),
                );
                searchkit.getQueryAccessor().setQueryString(query, true);
                await searchkit._search();
                if (searchkit.results) {
                    setCount(searchkit.results.hits.total);
                }
            })();
        }
    }, [query, dataSet, history]);

    let itemUrl = `/${dataSet.id}`;
    if (path) {
        itemUrl += `/${path}`;
    }
    if (query && storedLocation) {
        const storedParams = sessionStorage.getItem('query-params');
        if (storedParams) {
            const searchParams = new URLSearchParams(JSON.parse(storedParams)[dataSet.index]);
            searchParams.set('q', query);
            itemUrl += '?' + decodeURIComponent(searchParams.toString());
        }
    }

    return (
        <li>
            <a href={itemUrl} className={selected ? styles['active'] : undefined}>
                {dataSet.title}
            </a>
            {query && <span className={styles['item-count']}>{count !== undefined ? count : <div className={styles['loader']} title="Loading please wait" />}</span>}
        </li>
    );
};

const DataSetList = (props) => (
    <ul className={styles[`${props.homepage ? 'dataset-list-home' : 'dataset-list'}`]}>
        {Array.from(dataSets, ([key, dataSet]) => (
            <DataSetItem key={key} selected={props.dataSet.id === key} dataSet={dataSet} path={props.path} storedLocation={props.storedLocation} />
        ))}
    </ul>
);

export default withDataSet(DataSetList);
