import { PageSizeSelector } from 'searchkit';
import { withDataSet } from '../context.js';
import React from 'react';

class CustomPageSizeSelector extends PageSizeSelector {
    render() {
        // possible bug in searchkit caused issue with PageSizeSelector if 0 results were returned
        const pageSizeAccessor = this.getPageSizeAccessor();
        if (pageSizeAccessor) {
            const options = this.props.options.map((option) => ({ key: option, label: option }));
            const urlParams = new URLSearchParams(window.location.search);
            const sizeParam = urlParams.get('size');

            let selectedSize = '25';
            if (sizeParam) {
                selectedSize = sizeParam;
            }

            return (
                <>
                    <div data-qa="options" className="sk-toggle">
                        {options.map((item) => {
                            const itemKey = item.key.toString();
                            const itemClassName = itemKey === selectedSize ? 'sk-toggle-option sk-toggle__item is-active' : 'sk-toggle-option sk-toggle__item';
                            urlParams.set('size', itemKey);
                            const newParams = urlParams.toString();
                            return (
                                <div className={itemClassName} data-qa="option" data-key={itemKey} key={itemKey}>
                                    <a href={'?' + newParams} data-qa="label" className="sk-toggle-option__text">
                                        {itemKey}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </>
            );
        }
    }
}

export default withDataSet(CustomPageSizeSelector);
