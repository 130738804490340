import React from 'react';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { SearchkitComponent, Panel } from 'searchkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import styles from '../../assets/scss/DateRangeFilter.module.scss';
import DateRangeAccessor from './DateRangeAccessor.js';

const mapValues = require('lodash/mapValues');

class DateRangeFilter extends SearchkitComponent {
    constructor(props) {
        super(props);
        this.selectDates = this.selectDates.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleReset = this.handleReset.bind(this);

        this.state = {
            collapsed: true,
            startDate: this.getDateFromUrl('startDate') || props.startDate,
            endDate: this.getDateFromUrl('endDate') || props.endDate,
        };
    }

    getDateFromUrl(dateItem) {
        const params = new URLSearchParams(window.location.search);
        const dateStr = params.get(`${this.props.field}[${dateItem}]`);
        if (dateStr) {
            const date = DateTime.fromFormat(dateStr, this.props.dateFormat);
            if (date.isValid) {
                return date.toJSDate();
            }
        }
        return null;
    }

    defineAccessor() {
        return new DateRangeAccessor(this.props.id, {
            id: this.props.id,
            title: this.props.title,
            field: this.props.field,
            fieldOptions: this.props.fieldOptions,
            startDate: this.formatDate(this.props.startDate),
            endDate: this.formatDate(this.props.endDate),
            dateFormat: this.props.dateFormat,
            onReset: this.handleReset,
        });
    }

    handleReset() {
        this.setState({ startDate: this.props.startDate, endDate: this.props.endDate });
    }

    componentDidMount() {
        super.componentDidMount(arguments);
    }

    componentWillUnmount() {
        super.componentWillUnmount(arguments);
    }

    selectDates(value) {
        this.accessor.state = this.accessor.state.setValue({
            ...this.accessor.state.value,
            ...mapValues(value, (date) => this.formatDate(date)),
        });
        this.forceUpdate();
        this.searchkit.performSearch();
        this.setState(value);
    }

    formatDate(date, format) {
        return DateTime.fromJSDate(date).toFormat(format || this.props.dateFormat);
    }

    render() {
        if (!this.accessor) {
            return null;
        }

        const baseProps = {
            showMonthYearPicker: true,
            inline: true,
            calendarClassName: styles['calendar'],
            dateFormat: this.props.dateFormat,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };

        this.wrapperRef = React.createRef();

        return (
            <Panel title={this.props.title} className={`sk-panel ${styles['container']}`}>
                <div ref={this.wrapperRef}>
                    <button className={styles['button']} onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
                        {this.formatDate(this.state.startDate, 'MMM yyyy')}
                        <span>to</span>
                        {this.formatDate(this.state.endDate, 'MMM yyyy')}
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </button>
                    {!this.state.collapsed && (
                        <div className={styles['popout-container']}>
                            <button className={`btn-link ${styles['close-btn']}`} onClick={() => this.setState({ collapsed: true })}>
                                ×
                            </button>
                            <Row className="text-center">
                                <Col className={styles['calendar-container']}>
                                    <label>Date from</label>
                                    <DatePicker selected={this.state.startDate} maxDate={this.state.endDate} onChange={(date) => this.selectDates({ startDate: date })} selectsStart {...baseProps} />
                                </Col>
                                <Col className={styles['calendar-container']}>
                                    <label>Date to</label>
                                    <DatePicker selected={this.state.endDate} minDate={this.state.startDate} onChange={(date) => this.selectDates({ endDate: date })} selectsEnd {...baseProps} />
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Panel>
        );
    }
}

export default DateRangeFilter;
