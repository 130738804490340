import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { ResetFilters } from 'searchkit';

import * as Filters from './filters.js';
import ResetFiltersDisplay from './ResetFilters.js';

import styles from '../../assets/scss/FacetList.module.scss';
import { withDataSet } from '../../context.js';

export default withDataSet(({ dataSet }) => {
    return (
        <div className={styles['facets']}>
            <Row>
                <Col>
                    <h6 className={styles['filter-heading']}>FILTER</h6>
                </Col>
                <Col className="text-right">
                    <ResetFilters component={ResetFiltersDisplay} options={{ query: false, filter: true, pagination: false }} />
                </Col>
            </Row>
            <div id="facets">{dataSet.facets.map((facet, key) => React.createElement(Filters[facet.type], { ...facet.props, key }))}</div>
        </div>
    );
});
