import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import DataSetList from './DataSetList.js';
import SearchForm from './SearchForm.js';
import { LogoBanner } from './Footers';

import styles from '../assets/scss/HygienePages.module.scss';
import { withDataSet } from '../context.js';

const HygienePage = withDataSet(({ children, dataSet }) => {
    const history = useHistory();
    const [query, setQuery] = useState('');

    function onSubmit(e) {
        e.preventDefault();
        history.push(`/${dataSet.id}/search?q=${query}`);
    }

    return (
        <>
            <div className={styles['search-area']}>
                <DataSetList path="search" />
                <div className={styles['searchbox-container']}>
                    <SearchForm onChange={(e) => setQuery(e.target.value)} onSubmit={onSubmit} placeholder={dataSet.content.searchPlaceholder} />
                </div>
            </div>
            <div className={styles['content']}>{children}</div>
            <LogoBanner />
        </>
    );
});

const TermsAndConditionsPage = (props) => (
    <HygienePage {...props}>
        <h1>Terms and Conditions</h1>
        <p>
            NIHRIO encourages use of the data that it collects and publishes on this ScanMedicine website and the data are available for downloads. NIHRIO is located within Newcastle University and
            funded by the National Institute for Health Research (NIHR). NIHRIO, Newcastle University, NIHR, nor any of its data providers, nor any of its agencies, contractors, subcontracts or
            employees, make any warranties, expressed or implied, with respect to data contained in the database, and, furthermore, assume no liability for any party's use, or the results of such use,
            of any part of the database. This site is for information purposes only and does not aim to provide any medical advice.
        </p>
        <p>
            Use of the data derived from the datasets, that may be used to create tables and charts, is also subject to the Terms and Conditions set out here. By using the datasets or any
            presentations of data derived from them, you agree to be bound by and adhere to these Terms and Conditions, as may be amended from time to time by NIHRIO at its sole discretion. These
            terms and conditions are in effect as long as the user retains any of the data obtained.
        </p>
        <p>
            <span className="underline">Use of data</span>. Subject to these Terms and Conditions, NIHRIO grants, at no cost, worldwide, non-exclusive rights to use, reproduce, extract, download,
            copy, distribute, display or include the Datasets and data contained therein in other products for public health purposes.
        </p>
        <p>
            <span class="underline">Acknowledgement and Disclaimer</span>. By using the Datasets, you agree to appropriately acknowledge and provide attribution to NIHRIO and the country or countries
            having provided the underlying data (as indicated in the datasets) in the following format: NIHRIO, title of dataset, year, date of access, acknowledgement of the country or countries
            having provided the underlying data.
        </p>
        <p>
            Third party data included from different country registries should be attributed as listed below. NIHRIO does not assert any propriety rights to any portion of the third-party data. In
            addition, NIHRIO does not use any email addresses extracted for marketing or commercial purposes. The date the data was processed by ScanMedicine is shown on each record and NIHRIO aims to
            update the data on ScanMedicine from the registries listed on a daily basis. NIHRIO does not make any modifications to the data. However, in some instances, NIHRIO has standardised the
            format and/or data label for ease of use and reporting. The details of the standardisations applied on ScanMedicine can be provided on request.
        </p>
        <p>
            <strong>ClinicalTrials.gov:</strong> Data from this source located in the US, is attributed to ClinicalTrials.Gov which is a resource provided the US National Library of Medicine and is
            owned by the United States Government.
        </p>
        <p>
            <strong>UMIN-CTR:</strong> Data from this source located in Japan, is attributed to University hospital Medical Information Network Clinical Trial Registry (UMIN-CTR).{' '}
        </p>
        <p>
            <strong>EU Clinical Trials Register:</strong> The EU Clinical Trials Register contains information on interventional clinical trials on medicines conducted in the European Union (EU), or
            the European Economic Area (EEA) which started after 1 May 2004. Data from this registry is attributed to EU Clinical Trials Register.
        </p>
        <p>
            <strong>ISRCTN:</strong> Data from this source located in the UK, is attributed to ISRCTN (International Standard Randomised Controlled Trial Number) a database supported by the UK
            Department of Health &amp; Social Care, Medical Research Council, Wellcome Trust and the National Institute for Health Research (NIHR).
        </p>
        <p>
            <strong>ANZCTR:</strong> ANZCTR is an online registry of clinical trials being undertaken in Australia, New Zealand and elsewhere. Data from this registry is attributed to ANZCTR.{' '}
        </p>
        <p>
            <strong>CTRI:</strong> Data from this source located in India, is attributed to Clinical Trials Registry – India (CTRI), hosted by the ICMR – National Institute for Medical Statistics.{' '}
        </p>
        <p>
            <strong>CRIS:</strong> Data from this source located in the Republic of Korea (South Korea) is attributed to the Clinical Research Information Services (CRIS) hosted by the Korea Centers
            for Disease Control and Prevention (KCDC) with support from the Ministry of Health and Welfare (MOH).
        </p>
        <p>
            <strong>ReBEC:</strong> Data from this source located in Brazil, is attributed Registro Brasileiro de Ensaios Clinicos (ReBEC) a publicly owned, non-profit research organisation managed by
            the Oswaldo Cruz Foundation.
        </p>
        <p>
            <strong>REPEC:</strong> Data from this source located in Peru, is attributed to Registro Peruano de Ensayos Clinicos (REPEC) a non-profit online information system and contains records of
            clinical trials since 1995.
        </p>
        <p>
            <span class="underline">Third-party materials</span>. If you wish to reuse material from the datasets that is attributed to a third party, such as tables, figures or images, it is your
            responsibility to obtain permission from the copyright holder for such reuse. The risk of claims resulting from infringement of any third-party owned component in the datasets rests solely
            with the user.
        </p>
        <p>
            <span class="underline">Altering or Modifying the Datasets</span>. As part of the conditions of use, you may minimally alter or adapt figures and tables in the datasets to match the style
            of your publication. Any other alteration or modification of the Datasets (including abbreviations, additions, or deletions) may be made only with the prior written authorisation of NIHRIO
            or the third-party owner.
        </p>
        <p>
            <span class="underline">Prohibited Uses</span>. You will not sell or otherwise transfer the datasets and/or data contained therein to any third party. You will not use the datasets for any
            other purpose and/or in any other manner than as expressly provided herein, nor attempt to de-anonymise the datasets. Datasets shall not be used for or in conjunction with the promotion of
            a commercial enterprise and/or its product(s) or service(s), and/or in any way that suggests that NIHRIO endorses any specific company, products or services. Furthermore, you shall not use
            the Datasets in a manner that falsifies or misrepresents their content.
        </p>
        <p>
            <span class="underline">No NIHRIO endorsement</span>. You shall not state or imply that NIHRIO endorses the manner or purpose of your use or reproduction of the Datasets, or that NIHRIO
            endorses any entity, organisation, company, product or services.
        </p>
        <p>
            <span class="underline">No use of NIHRIO name and logo</span>. Without the prior written approval of NIHRIO, you will not use the name (or any abbreviation thereof) and/or emblem of the
            NIHR Innovation Observatory other than to report as the source of the data.
        </p>
        <p>
            <span class="underline">No Warranties by NIHRIO</span>. All reasonable precautions have been taken by NIHRIO to verify the accuracy of the datasets. Notwithstanding the foregoing, the
            Datasets are being provided to you without warranty of any kind, either expressed or implied. You will be solely responsible for your use of the datasets. In no event shall NIHRIO be
            liable for any damages arising from such use.
        </p>
        <p>
            <span class="underline">Modification/discontinuation of Datasets</span>. NIHRIO reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently,
            the datasets, any means of accessing or utilizing the Datasets with or without prior notice to you.
        </p>
        <p>
            <span class="underline">Your Indemnification of NIHIRO</span>. You agree to indemnify NIHRIO for, and hold NIHRIO harmless against, any claims and liabilities, including reasonable legal
            fees and costs, arising from your use of the datasets and/or breach of these Terms and Conditions.
        </p>
        <p>
            <span class="underline">Termination</span>. NIHRIO shall have the right to terminate this Agreement, and the use rights granted hereunder, at any time by written notice to you, without
            incurring any liability whatsoever to you or others. Furthermore, the licence and the rights granted under this Agreement shall terminate automatically upon any breach by you of the terms
            of this Agreement. Upon receipt of a written request from NIHRIO to that effect, you will promptly cease all use of, and destroy any and all copies of, the datasets provided by NIHRIO, and
            will confirm to NIHRIO in writing that you have complied with the foregoing, no later than seven (7) days after receipt of NIHRIO’s notification.
        </p>
        <p>
            <span class="underline">Entire Agreement</span>. These Terms and Conditions constitute the entire agreement between you and NIHRIO with respect to its subject matter. NIHRIO is not bound
            by any additional terms that may appear in any communication from you. Any amendment of these Terms and Conditions shall be subject to NIHRIO’s written agreement, it being agreed that
            NIHRIO may amend these Terms and Conditions from time to time at its sole discretion.
        </p>
        <p>
            <span class="underline">Dispute resolution</span>. Any dispute relating to the interpretation or application of these Terms and Conditions shall, unless amicably settled, be subject to
            conciliation. In the event of failure of the latter, the dispute shall be settled by arbitration. The arbitration shall be conducted in accordance with the modalities to be agreed upon by
            the parties or, in the absence of agreement, with the rules of arbitration of the International Chamber of Commerce. The parties shall accept the arbitral award as final.
        </p>
    </HygienePage>
);

const PrivacyPolicyPage = (props) => (
    <HygienePage {...props}>
        <h1>Privacy Notice</h1>
        <p>
            Your privacy on the Internet is of utmost importance to us at the NIHR Innovation Observatory (NIHRIO). This privacy statement describes our policy concerning the gathering and sharing of
            visitors’ information through the ScanMedicine web site
        </p>
        <h3>What information does NIHRIO collect?</h3>
        <p>
            ScanMedicine was developed and is managed by the NIHR Innovation Observatory (NIHRIO). NIHRIO is located within Newcastle University and funded by the National Institute for Health
            Research (NIHR).
        </p>
        <p>
            In general, you can browse the ScanMedicine site without telling us who you are or revealing any personal information about yourself. The only information we gather during general browsing
            is from standard server logs. These include your IP (Internet Protocol) address, domain name, browser type, operating system, and information such as the web site that referred you to us,
            the files you download, the pages you visit, and the dates/times of those visits.
        </p>
        <h3>What does NIHRIO do with the information it collects?</h3>
        <p>
            The information gathered during general browsing is used to analyse trends and usage of the ScanMedicine site and to improve the usefulness of the site. It is not connected with any
            personal information.
        </p>
        <h3>Notification of changes</h3>
        <p>
            Changes to this privacy policy will be posted on the ScanMedicine home page at <a href="https://scanmedicine.com/">https://scanmedicine.com</a>
        </p>
        <h3>Contact</h3>
        <p>
            For questions or queries regarding this privacy policy, please contact us at <a href="mailto:info@nihrio.ac.uk">info@nihrio.ac.uk</a>
        </p>
    </HygienePage>
);

const CookiePolicyPage = (props) => (
    <HygienePage {...props}>
        <h1>Cookies</h1>
        <p>
            A cookie is a small amount of data that is sent from a web server to your browser. It is normally used to assign a unique identification to your computer and securely store information
            such as user IDs, passwords, preferences, and online profiles.
        </p>
        <p>
            It is stored on the hard drive of your computer. You can choose not to have cookies delivered by the ScanMedicine site by changing your browser settings. Different web sites can send their
            own cookies to your computer. To protect your privacy, your browser only allows a web site to access the cookies it has already sent you, not the cookies sent by other sites.
        </p>
    </HygienePage>
);

const AboutPage = (props) => (
    <HygienePage {...props}>
        <h1>About ScanMedicine</h1>
        <p>
            Based at Newcastle University in the North-East of England, the NIHR Innovation Observatory is the home of health innovation futures scanning. We are an active research centre with a focus
            on the provision of early awareness signals and timely intelligence to support national decision making around health care innovation.
        </p>
        <p>
            ScanMedicine is a novel search platform dedicated to providing health care professionals, patients, carers, the public, policy-makers, and researchers with open access to the development
            pipeline underpinning health technology innovations. ScanMedicine comprises clinical trial records presenting standardised data from multiple national and international clinical trials
            registries, along with medical device approval data from the FDA. It offers users a convenient and effective single search interface with interactive visualisation features that can
            provide an overview of the health technology innovation landscape.
        </p>
    </HygienePage>
);

const Error404Content = (props) => (
    <div className={props.className}>
        <h1>Page not found</h1>
        <p>Sorry, that page cannot be found</p>
        <p>
            Try searching again, or <a href="/">return to the homepage</a>.
        </p>
    </div>
);

const Error404Page = (props) => (
    <HygienePage {...props}>
        <Error404Content />
    </HygienePage>
);

export { TermsAndConditionsPage, PrivacyPolicyPage, CookiePolicyPage, AboutPage, Error404Content, Error404Page };
