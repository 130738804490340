import React, { useState } from 'react';
import styles from '../assets/scss/Beta.module.scss';
import { FeedbackForm, ConfirmationModal } from './FormModals.js';

const BetaLabel = () => {
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <>
            <p className={styles['beta-label']}>
                <strong>Beta</strong> Please use <button onClick={() => setShowFeedbackForm(true)}>the feedback form</button> to help us improve the site.
            </p>
            <FeedbackForm
                show={showFeedbackForm}
                onHide={() => setShowFeedbackForm(false)}
                setShowFeedbackForm={setShowFeedbackForm}
                setShowConfirmation={setShowConfirmation}
                title={'Send us your feedback!'}
            />
            <ConfirmationModal show={showConfirmation} onHide={() => setShowConfirmation(false)} setShowConfirmation={setShowConfirmation} title={'Send us your feedback!'} />
        </>
    );
};

export default BetaLabel;
