import React from 'react';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear } from '@vx/scale';
import { AxisLeft } from '@vx/axis';
import includes from 'lodash/includes';

import styles from '../../assets/scss/Charts.module.scss';

export default ({ width, height, margin, items, selectedItems, setItems, multiselect, toggleItem, svgRef }) => {
    const data = items.map((item) => ({
        label: item.key,
        key: item.key,
        value: item.doc_count,
        selected: includes(selectedItems, item.key),
    }));

    const xMax = width - margin.left;
    const yMax = height;
    const y = (d) => d.label;
    const x = (d) => d.value || 0;

    const yScale = scaleBand({
        rangeRound: [0, yMax],
        domain: data.map(y),
        padding: 0.3,
    });

    const xScale = scaleLinear({
        rangeRound: [xMax, 0],
        domain: [Math.max(...data.map(x)), 0],
    });

    const toggleFunc = multiselect ? toggleItem : (key) => setItems([key]);

    return (
        <svg width={width} height={height} ref={svgRef} xmlns="http://www.w3.org/2000/svg">
            <Group className="chart-group">
                <Group left={margin.left}>
                    {data.map((d, i) => {
                        const barWidth = xScale(x(d));
                        const barHeight = yScale.bandwidth();
                        const barX = 0;
                        const barY = yScale(y(d));

                        return (
                            <Bar key={i} x={barX} y={barY} width={barWidth} height={barHeight} fill={d.selected ? styles['selectedBarColor'] : styles['barColour']} onClick={() => toggleFunc(d.key)} />
                        );
                    })}
                    <AxisLeft
                        scale={yScale}
                        stroke={styles['labelColour']}
                        hideAxisLine
                        hideTicks
                        tickLabelProps={() => ({
                            fill: styles['labelColour'],
                            fontSize: 13,
                            textAnchor: 'end',
                            dy: '0.33em',
                        })}
                    />
                </Group>
            </Group>
        </svg>
    );
};
