import React, { useState } from 'react';

import styles from '../assets/scss/Footers.module.scss';
import logoNIHRIO from '../assets/img/logo-nihrio.png';
import logoNewcastle from '../assets/img/logo-newcastle_university.png';
import { withDataSet } from '../context.js';

import { FeedbackForm, ConfirmationModal } from './FormModals.js';

const LogoBanner = () => (
    <div className={styles['banner']}>
        <div className={`${styles['banner-container']} align-items-center`}>
            <img src={logoNIHRIO} alt="An initiative of the NIHR Innovation Observatory" className={styles['banner-image--nihrio']} />
            <img src={logoNewcastle} alt="Newcastle University" className={styles['banner-image--nu']} />
        </div>
    </div>
);

const Footer = withDataSet(({ dataSet }) => {
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <footer className={styles['footer']}>
            <div>
                <span title={`version ${process.env.REACT_APP_VERSION}`}> © ScanMedicine | </span>
                <ul className={styles['footer-links']}>
                    <li>
                        <a href={`/${dataSet.id}/privacy-policy`} className={styles['footer-link']}>
                            Privacy
                        </a>
                    </li>
                    <li>
                        <a href={`/${dataSet.id}/cookie-policy`} className={styles['footer-link']}>
                            Cookies
                        </a>
                    </li>
                    <li>
                        <a href={`/${dataSet.id}/terms-and-conditions`} className={styles['footer-link']}>
                            Terms and Conditions
                        </a>
                    </li>
                    <li>
                        <a href={`/${dataSet.id}/about`} className={styles['footer-link']}>
                            About
                        </a>
                    </li>
                </ul>
            </div>
            <span className="text-right">
                <button onClick={() => setShowFeedbackForm(true)} className={styles['feedback-button']}>
                    Give us feedback on this page
                </button>
                <FeedbackForm
                    show={showFeedbackForm}
                    onHide={() => setShowFeedbackForm(false)}
                    setShowFeedbackForm={setShowFeedbackForm}
                    setShowConfirmation={setShowConfirmation}
                    title={'Send us your feedback!'}
                />
                <ConfirmationModal show={showConfirmation} onHide={() => setShowConfirmation(false)} setShowConfirmation={setShowConfirmation} title={'Send us your feedback!'} />
            </span>
        </footer>
    );
});

export { LogoBanner, Footer };
