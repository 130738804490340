import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import styles from '../assets/scss/Header.module.scss';
import Logo from '../assets/img/logo-scanmedicine-white.png';
import { withDataSet } from '../context.js';

import BetaLabel from './BetaLabel.js';

export default withDataSet((props) => {
    return (
        <header className={styles['header']}>
            <Row className={`${styles['header-container']} align-items-center`}>
                <Col className={styles['header-logo-container']}>
                    <Link to={'/'}>
                        <img className={styles['header-logo']} src={Logo} alt="Scan Medicine" />
                    </Link>
                </Col>

                <Col className={styles['header-beta']}>
                    <BetaLabel />
                </Col>
            </Row>
        </header>
    );
});
