import React from 'react';
import { useParams } from 'react-router-dom';
import { dataSets } from './config/dataSets.js';
import { useHistory } from 'react-router-dom';

const DataSetContext = React.createContext(dataSets.keys().next().value);

export const DataSetContextProvider = (props) => {
    const history = useHistory();
    const { dataSetId = dataSets.keys().next().value } = useParams();

    if (!dataSets.has(dataSetId)) {
        history.push(`/404`);
    }

    const currentDataSet = dataSets.get(dataSetId);

    return <DataSetContext.Provider value={currentDataSet}>{props.children}</DataSetContext.Provider>;
};

export function withDataSet(Component) {
    return function DataSetContextComponent(props) {
        return <DataSetContext.Consumer>{(contexts) => <Component {...props} {...{ dataSet: contexts }} />}</DataSetContext.Consumer>;
    };
}
