import React from 'react';
import { RangeFilter, NumericRefinementListFilter, CheckboxItemList, RefinementListFilter, Panel, block, renderComponent } from 'searchkit';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaDown, faSortAlphaUp, faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

import { Row, Col } from 'react-bootstrap';
import DateRangeFilter from './DateRangeFilter.js';
import styles from '../../assets/scss/FacetList.module.scss';

class OrderingPanel extends Panel {
    getOrderComponent() {
        return (
            <button onClick={this.props.toggleSortOrder} className="sort-order-button" title={this.props.sortTitle}>
                <FontAwesomeIcon icon={this.props.sortIcon} />
            </button>
        );
    }

    render() {
        const { title, mod, className, disabled, children } = this.props;
        var containerBlock = block(mod).state({ disabled });

        return (
            <div className={containerBlock.mix(className)}>
                <Row>
                    <Col xs={9} className={styles['facet-title']}>
                        {title}
                    </Col>
                    <Col xs={1}>{this.getOrderComponent()}</Col>
                </Row>
                <div className={containerBlock.el('content')}>{children}</div>
            </div>
        );
    }
}

class OrderedRefinementListFilter extends RefinementListFilter {
    constructor(props) {
        super(props);
        this.sortOptions = [
            { orderKey: '_term', orderDirection: 'asc', icon: faSortAlphaDown, title: 'Term descending' },
            { orderKey: '_term', orderDirection: 'desc', icon: faSortAlphaUp, title: 'Term ascending' },
            { orderKey: '_count', orderDirection: 'asc', icon: faSortAmountDown, title: 'Results descending' },
            { orderKey: '_count', orderDirection: 'desc', icon: faSortAmountUp, title: 'Results ascending' },
        ];

        //create seperate function to filter sortOptions?
        if (!this.props.sortTerm) {
            this.sortOptions = this.sortOptions.filter((option) => option.orderKey !== '_term');
        }
        if (!this.props.sortCount) {
            this.sortOptions = this.sortOptions.filter((option) => option.orderKey !== '_count');
        }
    }

    currentSortOption() {
        const { orderKey, orderDirection } = this.accessor.options;
        return this.sortOptions.find((sortOption) => sortOption.orderKey === orderKey && sortOption.orderDirection === orderDirection);
    }

    updateOrderValues(event) {
        const orderValues = event.target.value.split(' ');
        this.accessor.options.orderKey = orderValues[0];
        this.accessor.options.orderDirection = orderValues[1];
        this.searchkit.performSearch();
    }

    toggleSortOrder() {
        const nextSortOption = this.sortOptions[(this.sortOptions.findIndex((sortOption) => sortOption === this.currentSortOption()) + 1) % this.sortOptions.length];
        this.accessor.options.orderKey = nextSortOption.orderKey;
        this.accessor.options.orderDirection = nextSortOption.orderDirection;
        this.searchkit.performSearch();
    }

    render() {
        if (!this.accessor) return null;
        const { listComponent, showCount, title, id, countFormatter } = this.props;
        // don't show selected items if they haven't been in the search as they won't have a results count
        const listItems = this.getItems().filter((item) => !item.missing);

        return renderComponent(
            OrderingPanel,
            {
                title,
                className: id ? `filter--${id}` : undefined,
                disabled: !this.hasOptions(),
                toggleSortOrder: this.toggleSortOrder.bind(this),
                updateOrderValues: this.updateOrderValues.bind(this),
                orderDirection: this.accessor.options.orderDirection,
                orderKey: this.accessor.options.orderKey,
                sortIcon: this.currentSortOption().icon,
                sortTitle: this.currentSortOption().title,
                sortTerm: this.props.sortTerm,
                sortCount: this.props.sortCount,
            },
            [
                renderComponent(listComponent, {
                    key: 'listComponent',
                    className: styles['facet-list'],
                    items: listItems,
                    itemComponent: this.props.itemComponent,
                    selectedItems: this.getSelectedItems(),
                    toggleItem: this.toggleFilter.bind(this),
                    setItems: this.setFilters.bind(this),
                    docCount: this.accessor.getDocCount(),
                    showCount,
                    translate: this.translate,
                    countFormatter,
                }),
                this.renderShowMore(),
            ],
        );
    }
}

class CheckboxNumericRefinementListFilter extends NumericRefinementListFilter {
    static defaultProps = {
        listComponent: CheckboxItemList,
        containerComponent: Panel,
        multiselect: false,
        showCount: true,
    };
}

export { OrderedRefinementListFilter, RangeFilter, DateRangeFilter, CheckboxNumericRefinementListFilter, CheckboxItemList, RefinementListFilter };
