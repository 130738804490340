import { DateTime } from 'luxon';

const now = DateTime.local();
const dataSets = new Map();

dataSets.set('clinicaltrials', {
    id: 'clinicaltrials',
    index: 'nihrio',
    title: 'Clinical Trials',
    queryFields: [
        'MainID',
        'SecondaryID',
        'PublicTitle',
        'ScientificTitle',
        'HealthConditionOrProblemStudied',
        'InclusionCriteria',
        'ExclusionCriteria',
        'PrimaryOutcome',
        'SecondaryOutcome',
        'Interventions',
        'TrialStatus',
        'RecruitmentStatus',
        'PrimarySponsor',
        'SecondarySponsor',
    ],
    downloadFields: [
        'Registry',
        'MainID',
        'SecondaryID',
        'TrialStatus',
        'Phase',
        'PrimarySponsor',
        'SecondarySponsor',
        'PublicTitle',
        'ScientificTitle',
        'DateOfRegistration',
        'DateOfCompletion',
        'RecruitmentStatus',
        'HealthConditionOrProblemStudied',
        'StudyType',
        'StudyDesign',
        'Interventions',
        'TargetSampleSize',
        'CountriesOfRecruitment',
        'InclusionCriteria',
        'ExclusionCriteria',
        'Gender',
        'DateOfFirstEnrollment',
        'AgeMinimum',
        'AgeMaximum',
        'PrimaryOutcomes',
        'SecondaryOutcomes',
        'SourceOfMonetarySupport',
        'DocURL',
        'PubList',
    ],
    highlightFields: ['PublicTitle', 'ScientificTitle', 'HealthConditionOrProblemStudied', 'InclusionCriteria', 'ExclusionCriteria', 'PrimaryOutcome', 'SecondaryOutcome', 'Interventions'],
    sortOptions: [
        {
            label: 'Relevance',
            field: '_score',
            order: 'desc',
        },
        {
            label: 'Latest Registration Date',
            field: 'DateOfRegistration',
            order: 'desc',
        },
        {
            label: 'Earliest Registration Date',
            field: 'DateOfRegistration',
            order: 'asc',
        },
        {
            label: 'Keyword A-Z',
            field: 'PublicTitle.keyword',
            order: 'asc',
        },
        {
            label: 'Keyword Z-A',
            field: 'PublicTitle.keyword',
            order: 'desc',
        },
    ],
    sourceFilter: false,
    content: {
        searchPlaceholder: 'Search the clinical trial database',
        registryType: 'registries',
        listOfRegistries: [
            { name: 'AUSNZ', link: 'https://www.anzctr.org.au' },
            { name: 'Brazil', link: 'http://www.ensaiosclinicos.gov.br' },
            { name: 'EU', link: 'https://www.clinicaltrialsregister.eu' },
            { name: 'India', link: 'http://ctri.nic.in' },
            { name: 'Japan', link: 'https://upload.umin.ac.jp' },
            { name: 'Peru', link: 'https://ensayosclinicos-repec.ins.gob.pe' },
            { name: 'South Korea', link: 'https://cris.nih.go.kr' },
            { name: 'Sri Lanka', link: 'http://slctr.lk' },
            { name: 'Thailand', link: 'http://www.clinicaltrials.in.th' },
            { name: 'UK', link: 'http://www.isrctn.com' },
            { name: 'USA', link: 'https://clinicaltrials.gov' },
        ],
    },
    facets: [
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_term',
                orderDirection: 'asc',
                id: 'Phase',
                title: 'Phase',
                field: 'Phase',
                operator: 'OR',
                //termTransform: (term => Number.isInteger(parseInt(term)) ? `Phase ${term}` : term),
                size: 6,
            },
        },
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_term',
                orderDirection: 'asc',
                id: 'TrialStatusRaw',
                title: 'Trial Status',
                field: 'TrialStatusRaw',
                operator: 'OR',
                size: 10,
            },
        },
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_term',
                orderDirection: 'asc',
                id: 'Registry',
                title: 'Registry',
                field: 'Registry',
                operator: 'OR',
                size: 10,
            },
        },
        {
            type: 'DateRangeFilter',
            props: {
                startDate: now.set({ year: 1995 }).startOf('month').toJSDate(),
                endDate: now.startOf('month').toJSDate(),
                dateFormat: 'yyyy-MM-dd',
                field: 'DateOfRegistration',
                id: 'DateOfRegistration',
                title: 'Date of Registration',
            },
        },
        {
            type: 'DateRangeFilter',
            props: {
                startDate: now.set({ year: 1995 }).startOf('month').toJSDate(),
                endDate: now.startOf('month').toJSDate(),
                dateFormat: 'yyyy-MM-dd',
                field: 'DateOfFirstEnrolment',
                id: 'DateOfFirstEnrolment',
                title: 'Date of First Enrolment',
            },
        },
        {
            type: 'DateRangeFilter',
            props: {
                startDate: now.set({ year: 1995 }).startOf('month').toJSDate(),
                endDate: now.startOf('month').toJSDate(),
                dateFormat: 'yyyy-MM-dd',
                field: 'DateOfCompletion',
                id: 'DateOfCompletion',
                title: 'Date of Completion Year',
            },
        },
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_count',
                orderDirection: 'desc',
                id: 'CountriesOfRecruitmentRaw',
                title: 'Countries Of Recruitment',
                field: 'CountriesOfRecruitmentRaw',
                operator: 'OR',
                size: 10,
            },
        },
        {
            type: 'CheckboxNumericRefinementListFilter',
            props: {
                id: 'PubNumber',
                title: 'Scientific Publication',
                field: 'PubNumber',
                multiselect: true,
                options: [{ title: 'With Publications', from: 1, to: 10000 }],
            },
        },
        {
            type: 'RangeFilter',
            props: {
                min: 0,
                max: 500,
                field: 'TargetSampleSize',
                id: 'TargetSampleSize',
                title: 'Participant',
                showHistogram: true,
            },
        },
    ],
    visualisations: [
        {
            type: 'BarChartFilter',
            props: {
                id: 'Phase',
                title: 'Current trials by phase',
                field: 'Phase',
                multiselect: true,
                orderKey: '_term',
                margin: { left: 100 },
                widthRatio: 1,
            },
        },
        {
            type: 'BarChartFilter',
            props: {
                id: 'TrialStatusRaw',
                title: 'Trial status',
                field: 'TrialStatusRaw',
                multiselect: true,
                operator: 'OR',
                margin: { left: 150 },
                widthRatio: 1,
            },
        },
        {
            type: 'BarChartNumericFilter',
            props: {
                id: 'PubNumberRange',
                title: 'Number of publications',
                field: 'PubNumber',
                multiselect: true,
                operator: 'OR',
                margin: { left: 50 },
                widthRatio: 1,
                options: Array.from(Array(5).keys())
                    .map((i) => ({ title: i + '', from: i, to: i + 1 }))
                    .concat([{ title: '5+', from: 5, to: 10000 }]),
            },
        },
        {
            type: 'TreeMapFilter',
            props: {
                id: 'HealthConditionOrProblemStudiedChart',
                title: 'Top studied conditions',
                field: 'HealthConditionOrProblemStudiedRaw',
                multiselect: true,
                operator: 'OR',
                size: 30,
                widthRatio: 1.7,
            },
        },
        {
            type: 'ChoroplethFilter',
            props: {
                id: 'CountriesOfRecruitmentRaw',
                title: 'Country of Recruitment',
                field: 'CountriesOfRecruitmentRaw',
                multiselect: true,
                size: 500,
                widthRatio: 1.4,
            },
        },
    ],
    hitItem: {
        titleField: 'PublicTitle',
        detailFields: [
            {
                fieldName: 'TrialStatus',
                widgetType: 'StatusWidget',
                widgetProps: { title: 'Status' },
            },
            {
                fieldName: 'Phase',
                widgetType: 'PhaseWidget',
                widgetProps: { title: 'Phase' },
            },
            {
                fieldName: 'StudyType',
                widgetType: 'Widget',
                widgetProps: { title: 'Study Type' },
            },
            {
                fieldName: 'PrimarySponsor',
                widgetType: 'Widget',
                widgetProps: { title: 'Sponsor' },
            },
            {
                fieldName: 'CountriesOfRecruitment',
                widgetType: 'CountryWidget',
                widgetProps: { title: 'Countries' },
            },
            {
                fieldName: 'UpdatedTime',
                widgetType: 'DateWidget',
                widgetProps: { title: 'Date Updated' },
            },
        ],
        contentFields: [
            {
                fieldName: 'HealthConditionOrProblemStudied',
                widgetType: 'Widget',
                widgetProps: { title: 'Conditions' },
            },
            {
                fieldName: 'InclusionCriteria',
                widgetType: 'Widget',
                widgetProps: { title: 'Inclusion Criteria' },
            },
            {
                fieldName: 'ExclusionCriteria',
                widgetType: 'Widget',
                widgetProps: { title: 'Exclusion Criteria' },
            },
            {
                fieldName: 'Interventions',
                widgetType: 'Widget',
                widgetProps: { title: 'Interventions' },
            },
        ],
    },
    detailItem: {
        titleField: 'PublicTitle',
        metaDetailFields: [
            {
                fieldName: 'MainID',
                widgetType: 'Widget',
                widgetProps: { title: 'Main ID' },
            },
            {
                fieldName: 'SecondaryID',
                widgetType: 'Widget',
                widgetProps: { title: 'Secondary ID' },
            },
            {
                fieldName: 'TrialStatus',
                widgetType: 'StatusWidget',
                widgetProps: { title: 'Status' },
            },
            {
                fieldName: 'PrimarySponsor',
                widgetType: 'Widget',
                widgetProps: { title: 'Primary sponsor' },
            },
            {
                fieldName: 'SecondarySponsor',
                widgetType: 'Widget',
                widgetProps: { title: 'Secondary sponsor' },
            },
            {
                fieldName: 'CountriesOfRecruitment',
                widgetType: 'CountryWidget',
                widgetProps: { title: 'Countries' },
            },
            {
                fieldName: 'Contacts',
                widgetType: 'Widget',
                widgetProps: { title: 'Contacts' },
            },
        ],
        keyFactFields: [
            {
                fieldName: 'Registry',
                widgetType: 'Widget',
                widgetProps: { title: 'Registry' },
            },
            {
                fieldName: 'DateOfRegistration',
                widgetType: 'DateWidget',
                widgetProps: { title: 'Registration date' },
            },
            {
                fieldName: 'DateOfFirstEnrollment',
                widgetType: 'DateWidget',
                widgetProps: { title: 'First enrolment date' },
            },
            {
                fieldName: 'DateOfCompletion',
                widgetType: 'DateWidget',
                widgetProps: { title: 'Completion date' },
            },
            {
                fieldName: 'StudyType',
                widgetType: 'Widget',
                widgetProps: { title: 'Study type' },
            },
            {
                fieldName: 'Phase',
                widgetType: 'TextWidget',
                widgetProps: { title: 'Study phase' },
            },
            {
                fieldName: 'TrialStatus',
                widgetType: 'Widget',
                widgetProps: { title: 'Trial status' },
            },
            {
                fieldName: 'RecruitmentStatus',
                widgetType: 'Widget',
                widgetProps: { title: 'Recruitment status' },
            },
            {
                fieldName: 'Gender',
                widgetType: 'Widget',
                widgetProps: { title: 'Gender' },
            },
            {
                fieldName: 'AgeMinimum',
                widgetType: 'Widget',
                widgetProps: { title: 'Minimum Age' },
            },
            {
                fieldName: 'AgeMaximum',
                widgetType: 'Widget',
                widgetProps: { title: 'Maximum Age' },
            },
            {
                fieldName: 'HealthConditionOrProblemStudied',
                widgetType: 'Widget',
                widgetProps: { title: 'Health Condition / Problem Studied' },
            },
        ],
        externalLinkFields: [
            {
                fieldName: 'DocURL',
                widgetType: 'URLWidget',
                widgetProps: { title: 'URL' },
            },
            {
                fieldName: 'PubList',
                widgetType: 'PubMedWidget',
                widgetProps: { title: 'Publications' },
            },
        ],
        contentFields: [
            {
                fieldName: 'PrimaryOutcomes',
                widgetType: 'ContentWidget',
                widgetProps: { title: 'Primary outcomes' },
            },
            {
                fieldName: 'SecondaryOutcomes',
                widgetType: 'ContentWidget',
                widgetProps: { title: 'Secondary outcomes' },
            },
            {
                fieldName: 'Interventions',
                widgetType: 'ContentWidget',
                widgetProps: { title: 'Interventions' },
            },
            {
                fieldName: 'InclusionCriteria',
                widgetType: 'ContentWidget',
                widgetProps: { title: 'Inclusion criteria' },
            },
            {
                fieldName: 'ExclusionCriteria',
                widgetType: 'ContentWidget',
                widgetProps: { title: 'Exclusion criteria' },
            },
        ],
    },
});

dataSets.set('devices', {
    id: 'devices',
    index: 'devices',
    title: 'Devices',
    queryFields: ['title^5', 'country', 'review_panel', 'class', 'identifier', 'generic_name', 'product_code', 'applicant', 'regulation_number', 'url', 'medical_specialty'],
    downloadFields: [
        'title',
        'country',
        'review_panel',
        'class',
        'decision_date',
        'identifier',
        'generic_name',
        'summary',
        'product_code',
        'applicant',
        'regulation_number',
        'received_date',
        'url',
        'medical_specialty',
    ],
    highlightFields: ['title', 'name', 'product_code', 'applicant', 'class', 'generic_name'],
    sortOptions: [
        { label: 'Relevance', field: '_score', order: 'desc' },
        { label: 'Latest Decision Date', field: 'decision_date', order: 'desc' },
        { label: 'Earliest Decision Date', field: 'decision_date', order: 'asc' },
        {
            label: 'Title A-Z',
            field: 'title.keyword',
            order: 'asc',
        },
        {
            label: 'Title Z-A',
            field: 'title.keyword',
            order: 'desc',
        },
    ],
    content: {
        searchPlaceholder: 'US FDA-approved Devices, Diagnostics and Digital Apps',
        registryType: 'databases',
        listOfRegistries: [
            {
                name: 'PMA',
                link: 'https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfPMA/pma.cfm',
            },
            {
                name: 'HDE',
                link: 'https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfHDE/hde.cfm',
            },
            {
                name: '510(K)',
                link: 'https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfPMN/pmn.cfm',
            },
        ],
    },
    facets: [
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_count',
                orderDirection: 'desc',
                id: 'country',
                title: 'Country of Applicant',
                field: 'country',
                operator: 'OR',
                size: 7,
            },
        },
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_term',
                orderDirection: 'asc',
                id: 'review_panel',
                title: 'US FDA Review Panel',
                field: 'review_panel',
                operator: 'OR',
                size: 5,
            },
        },
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_term',
                orderDirection: 'asc',
                id: 'medicalSpecialty',
                title: 'US FDA Medical Specialty',
                field: 'medical_specialty',
                operator: 'OR',
                size: 5,
            },
        },
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_term',
                orderDirection: 'asc',
                id: 'class',
                title: 'Device Class',
                field: 'class',
                operator: 'OR',
                size: 10,
            },
        },
        {
            type: 'OrderedRefinementListFilter',
            props: {
                sortTerm: true,
                sortCount: true,
                orderKey: '_term',
                orderDirection: 'asc',
                id: 'source',
                title: 'FDA Database Source',
                field: 'source',
                operator: 'OR',
                size: 10,
            },
        },
        {
            type: 'RangeFilter',
            props: {
                min: 1960,
                max: 2020,
                field: 'decision_date_year',
                id: 'decisionDateYear',
                title: 'Decision Date',
                showHistogram: true,
            },
        },
    ],
    visualisations: [
        {
            type: 'BarChartFilter',
            props: {
                id: 'class',
                title: 'Device class',
                field: 'class',
                multiselect: true,
                orderKey: '_term',
                margin: { left: 100 },
                widthRatio: 1,
            },
        },
        {
            type: 'TreeMapFilter',
            props: {
                id: 'review_panel',
                title: 'Review Panel',
                field: 'review_panel',
                multiselect: true,
                operator: 'OR',
                size: 30,
                widthRatio: 2,
            },
        },
    ],
    hitItem: {
        titleField: 'title',
        detailUrlField: 'url',
        detailFields: [
            {
                fieldName: 'decision_date',
                widgetType: 'Widget',
                widgetProps: { title: 'Decision date' },
            },
            {
                fieldName: 'applicant',
                widgetType: 'TextWidget',
                widgetProps: { title: 'Applicant' },
            },
            {
                fieldName: 'identifier',
                widgetType: 'Widget',
                widgetProps: { title: 'FDA Identifier' },
            },
            {
                fieldName: 'regulation_number',
                widgetType: 'Widget',
                widgetProps: { title: 'Regulation Number' },
            },
            {
                fieldName: 'product_code',
                widgetType: 'TextWidget',
                widgetProps: { title: 'Product Code' },
            },
            {
                fieldName: 'country',
                widgetType: 'Widget',
                widgetProps: { title: 'Country' },
            },
        ],
        contentFields: [
            {
                fieldName: 'generic_name',
                widgetType: 'Widget',
                widgetProps: { title: 'Generic Name' },
            },
        ],
    },
});

export { dataSets };
