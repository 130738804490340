import React from 'react';
import styles from '../../assets/scss/FacetList.module.scss';

class ResetFiltersDisplay extends React.Component {
    render() {
        const { hasFilters, resetFilters } = this.props;

        return (
            <div onClick={resetFilters} className={styles['clear-filters']}>
                <div className={styles[`clear-filters-text${!hasFilters ? '-disabled' : ''}`]}>Clear filters</div>
            </div>
        );
    }
}

export default ResetFiltersDisplay;
