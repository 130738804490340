import React from 'react';
import { Link } from 'react-router-dom';
import truncate from 'lodash/truncate';
import get from 'lodash/get';
import { SearchkitComponent } from 'searchkit';
import ReactGA from 'react-ga';

import styles from '../assets/scss/Hits.module.scss';
import { withDataSet } from '../context.js';
import * as Widgets from './Widgets.js';
import { FlagData, ConfirmationModal } from './FormModals.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faDownload } from '@fortawesome/free-solid-svg-icons';

export const HitItem = withDataSet(({ dataSet, result, showDetailedView }) => {
    const [showFlagData, setShowFlagData] = React.useState(false);
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    function getValue(field, _truncate = false) {
        const value = get(result, `highlight['${field}']`, result._source[field]);
        if (_truncate) {
            return truncate(value, { length: 150, separator: /,? +/ });
        }
        return value;
    }

    return (
        <section className={styles['hit-item']}>
            <h4 className={styles['title']}>
                {dataSet.hitItem.detailUrlField ? (
                    <ReactGA.OutboundLink
                        eventLabel="hitItem.detailUrlField"
                        to={result._source[dataSet.hitItem.detailUrlField]}
                        target="_blank"
                        dangerouslySetInnerHTML={{ __html: getValue(dataSet.hitItem.titleField) }}
                    />
                ) : (
                    <Link to={`/${dataSet.id}/${encodeURIComponent(result._id)}`} dangerouslySetInnerHTML={{ __html: getValue(dataSet.hitItem.titleField) }} />
                )}
            </h4>
            {showDetailedView ? (
                <dl className={styles['content']}>
                    {dataSet.hitItem.contentFields.map((field, key) =>
                        React.createElement(Widgets[field.widgetType], { ...field.widgetProps, key }, <span dangerouslySetInnerHTML={{ __html: getValue(field.fieldName, true) }} />),
                    )}
                </dl>
            ) : null}

            <div className={styles['meta-container']}>
                <dl className={styles['detail']}>
                    {dataSet.hitItem.detailFields.map((field, key) => React.createElement(Widgets[field.widgetType], { ...field.widgetProps, key }, getValue(field.fieldName)))}
                </dl>
                <button onClick={() => setShowFlagData(true)} className={styles['flag-button']}>
                    <span title="Report Data Issue">
                        <FontAwesomeIcon icon={faFlag} />
                    </span>
                </button>
                <FlagData
                    show={showFlagData}
                    onHide={() => setShowFlagData(false)}
                    setShowFlagData={setShowFlagData}
                    setShowConfirmation={setShowConfirmation}
                    result={result}
                    title={'Report an issue'}
                />
                <ConfirmationModal show={showConfirmation} onHide={() => setShowConfirmation(false)} setShowConfirmation={setShowConfirmation} title={'Report an issue'} />
            </div>
        </section>
    );
});

class HitsList extends SearchkitComponent {
    constructor(props) {
        super(props);
        this.downloadResults = this.downloadResults.bind(this);
    }

    downloadLimit = 1000;

    downloadResults() {
        const { dataSet } = this.props;
        const query = { ...this.searchkit.query.query, from: 0, size: this.downloadLimit, _source: dataSet.downloadFields };
        (async () => {
            try {
                const response = await fetch(`/api/query/${dataSet.index}/_search?format=csv`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(query),
                });
                if (response.status !== 200) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
                const csvBody = await response.blob();
                const a = document.createElement('a');
                a.href = URL.createObjectURL(csvBody);
                a.download = 'download.csv';
                a.click();

                ReactGA.event({
                    category: 'Download',
                    action: 'results',
                });
            } catch (error) {
                ReactGA.exception({
                    description: `Error downloading results: ${error}`,
                    fatal: true,
                });
                console.log(error);
            }
        })();
    }

    render() {
        const { hits, showDetailedView } = this.props;

        return (
            <>
                <div className={styles['download-button-container']}>
                    <button
                        onClick={this.downloadResults}
                        disabled={this.searchkit.getHitsCount() >= this.downloadLimit}
                        title={
                            this.searchkit.getHitsCount() >= this.downloadLimit
                                ? 'Download option only available for less than ' + this.downloadLimit + ' results'
                                : 'Up to ' + this.downloadLimit + ' results will be downloaded'
                        }
                    >
                        <span title="Download as CSV">
                            <FontAwesomeIcon icon={faDownload} />
                        </span>{' '}
                        Download as CSV
                    </button>
                </div>

                {hits.map((hit, i) => (
                    <HitItem key={i} result={hit} showDetailedView={showDetailedView} />
                ))}
            </>
        );
    }
}

export default withDataSet(HitsList);
