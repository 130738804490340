import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import Header from './Header.js';
import { Footer } from './Footers.js';
import DetailPage from './DetailPage.js';
import HomePage from './HomePage.js';
import ResultsPage from './ResultsPage.js';
import { TermsAndConditionsPage, PrivacyPolicyPage, CookiePolicyPage, AboutPage, Error404Page } from './HygienePages.js';

import { dataSets } from '../config/dataSets.js';
import { DataSetContextProvider } from '../context.js';
import styles from '../assets/scss/App.module.scss';

TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
});
ReactGA.initialize(process.env.REACT_APP_GA_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const PageWrapper = (props) => (
    <DataSetContextProvider>
        <Header />
        <div className={styles['main']}>{props.children}</div>
        <Footer />
    </DataSetContextProvider>
);

export default () => {
    const dataSetPattern = Array.from(dataSets, ([key]) => key).join('|');

    return (
        <Router render={(location) => ReactGA.pageview(location)}>
            <Switch>
                <Route exact path={['/', `/:dataSetId(${dataSetPattern})`]}>
                    <DataSetContextProvider>
                        <HomePage />
                    </DataSetContextProvider>
                </Route>
                <Route path={['/terms-and-conditions', `/:dataSetId(${dataSetPattern})/terms-and-conditions`]}>
                    <PageWrapper>
                        <TermsAndConditionsPage />
                    </PageWrapper>
                </Route>
                <Route path={['/privacy-policy', `/:dataSetId(${dataSetPattern})/privacy-policy`]}>
                    <PageWrapper>
                        <PrivacyPolicyPage />
                    </PageWrapper>
                </Route>
                <Route path={['/cookie-policy', `/:dataSetId(${dataSetPattern})/cookie-policy`]}>
                    <PageWrapper>
                        <CookiePolicyPage />
                    </PageWrapper>
                </Route>
                <Route path={['/about', `/:dataSetId(${dataSetPattern})/about`]}>
                    <PageWrapper>
                        <AboutPage />
                    </PageWrapper>
                </Route>
                <Redirect from="/search" to={`/${dataSets.keys().next().value}/search`} />
                <Route path={`/:dataSetId(${dataSetPattern})/search`}>
                    <PageWrapper>
                        <ResultsPage />
                    </PageWrapper>
                </Route>
                <Route path={`/:dataSetId(${dataSetPattern})/:id`}>
                    <PageWrapper>
                        <DetailPage />
                    </PageWrapper>
                </Route>
                <Route exact path="*">
                    <PageWrapper>
                        <Error404Page />
                    </PageWrapper>
                </Route>
            </Switch>
        </Router>
    );
};
