import { SearchkitManager, SearchRequest } from 'searchkit';
import ReactGA from 'react-ga';
import { DateTime } from 'luxon';
import isEqual from 'lodash/isEqual';

export class SynonymSearchKitManager extends SearchkitManager {
    onlyFlag = '[only]';

    constructor(host, options = {}, initialState = {}) {
        const { expandQuery, setExpandQuery, ...baseOptions } = options;
        super(host, baseOptions, initialState);
        this.expandQuery = expandQuery;
        this.setExpandQuery = setExpandQuery || Function.prototype;
        this.synonyms = [];
    }

    async _search() {
        this.state = this.accessors.getState();
        let term = this.state.q;
        if (term) {
            term = term.trim();
            if (term.endsWith(this.onlyFlag)) {
                term = term.substring(0, term.length - this.onlyFlag.length).trim();
                this.expandQuery = false;
            } else {
                this.expandQuery = true;
            }
        }

        this.setExpandQuery(this.expandQuery);

        const query = this.buildQuery();

        if (!this.shouldPerformSearch(query)) {
            return Promise.resolve(this.getResultsAndState());
        }
        if (this.results && this.query && isEqual(query.getJSON(), this.query.getJSON())) {
            return Promise.resolve(this.getResultsAndState());
        }
        this.query = query;
        this.loading = true;
        this.emitter.trigger();

        const queryObject = this.queryProcessor(this.query.getJSON());

        if (term) {
            if (this.expandQuery) {
                const queryStr = term.replace(/\|./g, ''); // TODO: check if '|' is the only character to escape

                const fetchTimeout = process.env.SYNONYM_FETCH_TIMEOUT || 2000;
                let didTimeOut = false;

                try {
                    const now = DateTime.local();

                    const response = await new Promise((resolve, reject) => {
                        this.synonyms = [];

                        const timeout = setTimeout(() => {
                            didTimeOut = true;
                            reject(new Error('Request timed out'));
                        }, fetchTimeout);

                        fetch(`/api/expand?query=${queryStr}`)
                            .then((response) => {
                                clearTimeout(timeout);
                                if (!didTimeOut) {
                                    resolve(response);
                                }
                            })
                            .catch(function (err) {
                                reject(err);
                            });
                    });

                    const content = await response.text();

                    ReactGA.timing({
                        category: 'Synonym lookup',
                        variable: 'load',
                        value: Math.abs(now.diffNow().milliseconds),
                    });

                    if (content.toLocaleLowerCase().startsWith(`${term.toLocaleLowerCase()} or `)) {
                        this.synonyms = content.split('OR').map((s) => s.trim());
                        queryObject.query.simple_query_string.query = this.synonyms.map((s) => `"${s.trim()}"`).join('|');
                    }
                } catch (error) {
                    ReactGA.exception({
                        description: `Error fetching synonyms: ${error}`,
                        fatal: true,
                    });
                    console.log(error);
                }
            } else {
                // TODO: manipulating the object here is not ideal - work out how to set the query further up the chain
                queryObject.query.simple_query_string.query = term;
            }
        }

        this.currentSearchRequest && this.currentSearchRequest.deactivate();
        this.currentSearchRequest = new SearchRequest(this.transport, queryObject, this);
        await this.currentSearchRequest.run();

        return this.getResultsAndState();
    }
}
