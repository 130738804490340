import React from 'react';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import kebabCase from 'lodash/kebabCase';
import isArray from 'lodash/isArray';
import { DateTime } from 'luxon';

import styles from '../assets/scss/Widgets.module.scss';

function getWidgetValue(candidate) {
    // TODO: Make it so we don't need this
    // The way widgets are loaded with React.createElement means we always have
    // to have an element
    if (!isNull(candidate) && !isUndefined(candidate) && candidate.props && candidate.props.dangerouslySetInnerHTML) {
        return candidate.props.dangerouslySetInnerHTML.__html;
    }

    return candidate;
}

export const Widget = ({ title, children }) => {
    if (isNull(children)) {
        return null;
    }
    return (
        <>
            <dt>{title}</dt>
            <dd>{children}</dd>
        </>
    );
};

export const TextWidget = ({ children, ...props }) => (
    <Widget {...props}>
        {(isArray(children) ? children : [children]).map((child, i) => (
            <span key={i} dangerouslySetInnerHTML={{ __html: `${child}${isArray(children) && children.length > i + 1 ? ', ' : ''}` }} />
        ))}
    </Widget>
);

export const PhaseWidget = ({ children, ...props }) => {
    return (
        <Widget {...props}>
            {(isArray(children) ? children : [children]).map((phase, key) => (
                <span key={key} className={`${styles['lozenge']} ${styles['phase-' + phase]}`}>
                    {phase}
                </span>
            ))}
        </Widget>
    );
};

export const StatusWidget = ({ children, ...props }) => (
    <Widget {...props}>
        <span className={`${styles['lozenge']} ${styles['status-' + kebabCase(children)]}`}>{children}</span>
    </Widget>
);

export const CountryWidget = ({ children, ...props }) => {
    if (!isArray(children)) {
        return null;
    }
    return <Widget {...props}>{children.join(', ')}</Widget>;
};

export const DateWidget = ({ children, ...props }) => {
    const value = getWidgetValue(children);
    return <Widget {...props}>{DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)}</Widget>;
};

export const URLWidget = ({ children, ...props }) => {
    const value = getWidgetValue(children.replace(/&amp;/g, '&'));
    if (!value) {
        return null;
    }
    return (
        <Widget {...props}>
            <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
            </a>
        </Widget>
    );
};

export const PubMedWidget = ({ children, ...props }) => {
    const value = getWidgetValue(children);
    if (!value || !value.length) {
        return null;
    }
    return (
        <Widget {...props}>
            {value.map((item, i) => (
                <React.Fragment key={i}>
                    <a href={`https://www.ncbi.nlm.nih.gov/pubmed/${item}`} target="_blank" rel="noopener noreferrer">
                        {item}
                    </a>
                    ,&nbsp;
                </React.Fragment>
            ))}
        </Widget>
    );
};

export const ContentWidget = ({ children, ...props }) => {
    return (
        <>
            <h3 id={kebabCase(props.title)}>
                {isArray(children)}
                {props.title}
            </h3>
            {isArray(children) ? (
                <ul>
                    {children.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            ) : (
                <p>{children}</p>
            )}
        </>
    );
};
