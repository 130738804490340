import React, { useState } from 'react';
import { Modal, Form, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngry, faFrown, faMeh, faSmile, faLaugh, faComment, faBug, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

import styles from '../assets/scss/FormModals.module.scss';
import { withDataSet } from '../context.js';

function sendMail(data) {
    fetch('/api/mailfwd', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            //console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
}

const Header = ({ title }) => {
    return (
        <Modal.Header closeButton className={styles['modal-header']}>
            <Modal.Title id="contained-modal-title-vcenter" className={styles['modal-title']}>
                {title}
            </Modal.Title>
        </Modal.Header>
    );
};

const BasicFeedbackForm = ({ comment, setComment, setName, setEmail }) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>Comments:</Form.Label>
                <Form.Control as="textarea" rows="3" value={comment} onChange={(event) => setComment(event.target.value)} />
            </Form.Group>
            <Form.Row>
                <Col>
                    <Form.Label>Your name</Form.Label>
                    <Form.Control placeholder="Your name" onChange={(event) => setName(event.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} />
                </Col>
            </Form.Row>
        </div>
    );
};

const FlagData = withDataSet((props) => {
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const { setShowFlagData, setShowConfirmation, dataSet, result, ...modalProps } = props;

    const onSubmit = (event) => {
        event.preventDefault();
        setComment('');
        setName('');
        setEmail('');

        // Send form data to given endpoint/email

        const resultUrl = `${window.location.origin}/${dataSet.id}/${encodeURIComponent(result._id)}`;
        const currentUrl = window.location.href;
        const dataSetName = dataSet.id;
        const resultId = result._id;

        sendMail({
            subject: 'Data issue reported',
            currentUrl,
            resultUrl,
            dataSetName,
            resultId,
            comment,
            name,
            email,
        });

        setShowFlagData(false);
        setShowConfirmation(true);
    };

    return (
        <Modal {...modalProps} centered onShow={() => ReactGA.modalview('flag-data-issue')}>
            <Header title={modalProps.title} />
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <BasicFeedbackForm comment={comment} setComment={setComment} setName={setName} setEmail={setEmail} textLabel={'Comments:'} />
                </Modal.Body>
                <Modal.Footer>
                    <button className={styles['form-button']} type="submit">
                        Submit
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

const FeedbackForm = (props) => {
    const [rating, setRating] = useState(null);
    const [category, setCategory] = useState(null);
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const { setShowFeedbackForm, setShowConfirmation, ...modalProps } = props;

    const onSubmit = (event) => {
        event.preventDefault();
        setRating(null);
        setCategory(null);
        setComment('');
        setName('');
        setEmail('');

        // Send form data to given endpoint/email
        sendMail({
            subject: 'Feedback',
            rating,
            category,
            comment,
            name,
            email,
        });

        setShowFeedbackForm(false);
        setShowConfirmation(true);
    };

    return (
        <Modal {...modalProps} centered onShow={() => ReactGA.modalview('feedback')}>
            <Header title={modalProps.title} />
            <Form onSubmit={onSubmit} className={styles['feedback-form']}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>What's your opinion on this webpage?</Form.Label>
                        <div>
                            <ToggleButtonGroup type="radio" name="rating" onChange={(value) => setRating(value)} className={styles['toggle-button-group']}>
                                <ToggleButton className={styles['toggle-button-emoticon']} value={1}>
                                    <FontAwesomeIcon icon={faAngry} size="3x" />
                                </ToggleButton>
                                <ToggleButton className={styles['toggle-button-emoticon']} value={2}>
                                    <FontAwesomeIcon icon={faFrown} size="3x" />
                                </ToggleButton>
                                <ToggleButton className={styles['toggle-button-emoticon']} value={3}>
                                    <FontAwesomeIcon icon={faMeh} size="3x" />
                                </ToggleButton>
                                <ToggleButton className={styles['toggle-button-emoticon']} value={4}>
                                    <FontAwesomeIcon icon={faSmile} size="3x" />
                                </ToggleButton>
                                <ToggleButton className={styles['toggle-button-emoticon']} value={5}>
                                    <FontAwesomeIcon icon={faLaugh} size="3x" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Please select your feedback category below:</Form.Label>
                        <ToggleButtonGroup type="radio" name={'category'} onChange={(value) => setCategory(value)} className={styles['toggle-button-group']}>
                            <ToggleButton className={styles['toggle-button-category']} value={'Suggestion'}>
                                <FontAwesomeIcon icon={faComment} /> Suggestion
                            </ToggleButton>
                            <ToggleButton className={styles['toggle-button-category']} value={'Bug'}>
                                <FontAwesomeIcon icon={faBug} /> Bug
                            </ToggleButton>
                            <ToggleButton className={styles['toggle-button-category']} value={'Compliment'}>
                                <FontAwesomeIcon icon={faThumbsUp} /> Compliment
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Form.Group>
                    <BasicFeedbackForm comment={comment} setComment={setComment} setName={setName} setEmail={setEmail} textLabel={'Please leave your feedback below:'} />
                </Modal.Body>
                <Modal.Footer>
                    <button className={styles['form-button']} type="submit">
                        Submit
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

const ConfirmationModal = (props) => {
    const { setShowConfirmation, ...modalProps } = props;
    return (
        <Modal {...modalProps} centered>
            <Header title={modalProps.title} />
            <Modal.Body>
                <h4 className={styles['confirmation-text']}>Your feedback has been submitted, thank you.</h4>
            </Modal.Body>
            <Modal.Footer>
                <button className={styles['form-button']} onClick={() => setShowConfirmation(false)}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export { FlagData, FeedbackForm, ConfirmationModal };
